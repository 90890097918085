import React from "react";
import styled from "styled-components";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";

import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import DescriptionIcon from "@mui/icons-material/Description";
import DownloadIcon from "@mui/icons-material/Download";
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';

// import {ReactComponent as GitHubIcon} from "@/assets/black-svg/github.svg"
// import {ReactComponent as LinkedInIcon} from "@/assets/black-svg/linkedin.svg"
import { ReactComponent as UpWorkIcon } from "@/assets/black-svg/upwork.svg";
// import {ReactComponent as DescriptionIcon} from "@/assets/black-svg/curriculum-vitae-resume.svg"
// import {ReactComponent as EmailIcon} from '@/assets/black-svg/mail.svg'
import { ReactComponent as TopRatedBadgeIcon } from "@/assets/black-svg/badges/topRatedBadge.svg";

import { motion } from "framer-motion";

import { PDFViewer } from "@/components/PDFViewer/PDFViewer";
import { CustomIcon } from "@/components/ui";
import MyStandardResume from "@/assets/resume/KHALED_MEHERGUEF_Resume.pdf";
import { useSelector } from "react-redux";

const iconsVariants = {
    hidden: { opacity: 0, x: "-100vw" },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            duration: 0,
            // type:"spring",
            // bounce:0.3,
            delay: 0,
            when: "beforeChildren",
            delayChildren: 0,
            staggerChildren: 0.0,
        },
    },
    hover: {},
};

export const ContactsAccountsIcons = (props) => {
    const { darkTheme } = useSelector((state) => state.theme);

    const [openedPDFDialog, setOpenedPDFDialog] = React.useState(false);

    const handleClickOpenPDFDialog = () => {
        setOpenedPDFDialog(true);
    };

    const handleClosePDFDialog = () => {
        setOpenedPDFDialog(false);
    };

    const mailMe = () => {
        window.location =
            "mailto:cntcts.dev.loker@outlook.com?subject=contact from portfolio&body=Hi, Devloker";
    };

    const iconLinks = [
        {
            label: "Github/DEVLOKER",
            icon: <GitHubIcon />,
            link: "https://github.com/DEVLOKER",
            target: "_blank",
            action: null,
        },
        {
            label: "LinkedIn/DEVLOKER",
            icon: <LinkedInIcon />,
            link: "http://www.linkedin.com/in/dev-loker",
            target: "_blank",
            action: null,
        },
        {
            label: "UpWork/DEVLOKER",
            icon: <UpWorkIcon />,
            link: "https://www.upwork.com/freelancers/~01abb360bdb5d8e5df",
            target: "_blank",
            badge: (
                <TopRatedBadgeIcon
                    style={{
                        width: 22,
                        height: 22,
                    }}
                />
            ),
            action: null,
        },
        {
            label: "dev.to/devloker",
            icon: <IntegrationInstructionsIcon />,
            link: "https://dev.to/devloker",
            target: "_blank",
            action: null,
        },
        {
            label: "Resume", // Curriculum Vitae
            icon: <DescriptionIcon />,
            link: "#",
            target: "",
            action: handleClickOpenPDFDialog,
        },
        {
            label: "Contact Me",
            icon: <EmailIcon />,
            link: "#",
            target: "_blank",
            action: mailMe,
        },
    ];
    // initial='hidden' animate='visible'
    // motion.div variants={iconsVariants}
    return (
        <motion.div variants={iconsVariants}>
            <IconsContainer sx={{ my: 3, mx: 2 }}>
                {iconLinks.map((elm, index) => (
                    <CustomIcon {...elm} index={index} key={index} />
                ))}
            </IconsContainer>

            <Dialog
                open={openedPDFDialog}
                onClose={handleClosePDFDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                // fullScreen={true}
                scroll="body"
                // fullWidth={true}
                maxWidth="xl"
                style={{ filter: darkTheme ? "invert(1)" : "invert(0)" }}
            >
                <DialogTitle
                    id="alert-dialog-title"
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                    }}
                >
                    <Box>
                        {/* My Curriculum Vitae */}
                        My Resume
                    </Box>
                    <Box>
                        <a
                            href={MyStandardResume}
                            download="Devloker Resume"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: "none" }}
                        >
                            <Button
                                variant="contained"
                                color="error"
                                size="medium"
                                startIcon={<DownloadIcon />}
                            >
                                Save as PDF
                            </Button>
                        </a>
                    </Box>
                </DialogTitle>
                <PDFContenet dividers={false}>
                    <Box
                        noValidate
                        component="form"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            m: "auto",
                            width: "fit-content",
                        }}
                    >
                        <PDFViewer url={MyStandardResume} />
                    </Box>
                </PDFContenet>
                <DialogActions>
                    <CancelButton onClick={handleClosePDFDialog} autoFocus>
                        Close
                    </CancelButton>
                </DialogActions>
            </Dialog>
        </motion.div>
    );
};

const IconsContainer = styled(Box)`
    && {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
    }
`;

const PDFContenet = styled(DialogContent)`
    &&,
    && .react-pdf__Document {
        text-align: -webkit-center;
        text-align: center;
    }
`;

const CancelButton = styled(Button)`
    && {
        color: var(--secondary-color);
    }
`;
