import { createSlice } from "@reduxjs/toolkit"
import ColorConverter from '@/helpers/ColorConverter'

export const themeSlice = createSlice({
    name: "theme",
    //  #1cb5e0     /       #000051                 #e34a1f     /     #ffffae
    //  #96e000     /       #001800                 #691fff     /     #ffe7ff  
    //  #9e14de     /       #dc1451
    //  #87097d     /       #fbbc05
    //  #ef9273     /       #0d0d0d       #fef9f8
    //  #2b6777     /       #52ab98           #c8d8e4 #f2f2f2
    //  #ed7966     /       #303179         #141850 #fae5df #f5cac2
    //  #062F4F     /       #B82601           #813772
    //  #FC4A1A     /       #F7B733           #4ABDAC
    //  #76323F     /       #565656           #C09F80
    //  #0F1626     /       #FF533D           #AB987A
    initialState: { 
        darkTheme       : false, filter : 'invert(0)', 
        primaryColor    : ColorConverter.hexToRGB('#dc1451'),
        secondaryColor  : ColorConverter.hexToRGB('#546785')
    },
    reducers: {
        toggleTheme : (state, action)=> {
            // state.darkTheme?(
            //     state = { ...state, darkTheme: !state.darkTheme, primaryColor : ColorConverter.hexToRGB('#1cb5e0'), secondaryColor  : ColorConverter.hexToRGB('#000051') }
            // ):(
            //     state = { ...state, darkTheme: !state.darkTheme, primaryColor : ColorConverter.hexToRGB('#1cb5e0'), secondaryColor  : ColorConverter.hexToRGB('#000051')}
            // )
            state = { ...state, darkTheme: !state.darkTheme}//, filter: state.filter === 'invert(0)'? 'invert(100)': 'invert(0)' }
            return state
        },

        toggleInvertFilter : (state, action)=> {
            state = {   ...state, 
                        filter: state.filter === 'invert(0)'? 'invert(100)': 'invert(0)',
                        primaryColor: state.filter === 'invert(0)'? ColorConverter.hexToRGB('#23ebae'): ColorConverter.hexToRGB('#dc1451')
                    }
            //console.log(`toggleFilter : ${state.filter}`)
            return state
        },

        changePrimaryColor : (state, action)=> {
            state = {  ...state, primaryColor: action.payload }
            return state
        },

        changeSecondaryColor : (state, action)=> {
            state = { ...state, secondaryColor: action.payload }
            return state
        }
        //  document.documentElement.style.setProperty('--base',this.state.color);
        // const color = getComputedStyle(document.documentElement).getPropertyValue('--primary-color');
    }
})

export const {toggleTheme, toggleInvertFilter, changePrimaryColor, changeSecondaryColor} = themeSlice.actions;
//export default themeSlice