import { createGlobalStyle } from "styled-components"

import Roboto from '@/styles/fonts/Roboto/Roboto-Regular.ttf'
import Ubuntu from '@/styles/fonts/Ubuntu/Ubuntu-R.ttf'
import DancingScript from '@/styles/fonts/DancingScript/DancingScript-VariableFont_wght.ttf'

import CustomCursor from '@/styles/cursor/cursor-36x36.png'


export default createGlobalStyle`

    @font-face {
        font-family: 'Roboto';
        src: url(${Roboto}) format('truetype');
        font-display: block;
    }

    @font-face {
        font-family: 'Ubuntu';
        src: url(${Ubuntu}) format('truetype');
        font-display: block;
    }

    @font-face {
        font-family: 'Dancing Script';
        src: url(${DancingScript}) format('truetype');
        font-display: block;
    }

    :root{
        --rgb-primary-color : 28, 181, 224;
        --rgb-secondary-color : 0, 0, 81;

        --primary-color: rgb(var(--rgb-primary-color));
        --secondary-color: rgb(var(--rgb-secondary-color));

        --icon-size: 2rem;
        --indicator-spacing: calc(var(--icon-size) / 8);
        --border-radius: calc(var(--icon-size) / 4);
        --nav-item-padding: calc(var(--icon-size) / 2);

        --portfolio-font : 'Ubuntu';
    }
    html {
        background: #ffffff;
        color: #000000;
    }
    html, * {
        /* cursor: url(${CustomCursor}) 32 32, auto; */
    }
    body, * {
        margin: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-family: var(--portfolio-font);
    }
    ::-webkit-scrollbar-track {
        /* background: var(--primary-color); */
    }
    ::-webkit-scrollbar-thumb {
        /* background-color: var(--secondary-color); */
        border-radius: 0px;
        opacity: 0.5;
    }
    ::-webkit-scrollbar-thumb:hover{
        opacity: 1;
    }
    ::-webkit-scrollbar{
        /* width: 0px; */
    }

    .MuiTypography-root {
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 1.3rem;
        letter-spacing: 1px;
        font-family: var(--portfolio-font);
        /* text-transform: capitalize; */
    }

    a, a:visited {
        color: inherit;
    }
`;