import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Grid, Typography } from "@mui/material";
import Tilt from "react-parallax-tilt";
import { useSelector } from "react-redux";
import { ReactComponent as TopRatedBadgeIcon } from "@/assets/black-svg/badges/topRatedBadge.svg";

const bottomVariants = {
    hidden: { opacity: 0, scale: 0 },
    visible: (custom) => {
        return {
            opacity: 1,
            y: 0,
            scale: 1,
            transition: {
                duration: 1,
                type: "tween",
                ease: "easeInOut",
                when: "beforeChildren",
                delayChildren: 1,
                staggerChildren: 1.5,
                delay: custom === 2 ? 6 : 9,
            },
        };
    },
    hover: {},
};

export const AboutMe = (props) => {
    const { darkTheme } = useSelector((state) => state.theme);

    // const [isReadMore, setIsReadMore] = useState(true)
    // const toggleReadMore = () => setIsReadMore(!isReadMore)

    return (
        <motion.div
            variants={bottomVariants}
            custom={props.order}
            onAnimationComplete={(e) => {}}
        >
            <Tilt tiltAxis={"y"} tiltMaxAngleY={7}>
                <AboutMeContainer
                    darktheme={`${darkTheme}`}
                    sx={{ my: 3, mx: 2 }}
                >
                    <div className="shortContent">
                        <Typography variant="h6" component="h6">
                            I'm a full stack developer, one of top 10%
                            freelancers on Upwork by earning
                            <a
                                href="https://support.upwork.com/hc/en-us/articles/211068468-Top-Rated"
                                target="_blank"
                                rel="noreferrer"
                                style={{ textDecoration: "none" }}
                            >
                                <strong style={{ marginLeft: 5 }}>
                                    "Top Rated"
                                </strong>
                                <TopRatedBadgeIcon
                                    style={{
                                        width: 25,
                                        height: 25,
                                        marginLeft: 5,
                                        marginRight: 5,
                                    }}
                                />
                            </a>
                            badge, passionate for delivering high-quality
                            applications, you can rely on me for solving complex
                            programming problems.
                        </Typography>
                    </div>

                    {/* {
                        !isReadMore && (
                            <div className='extraContent' >
                                <Typography variant="h6" component="h2">
                                    blalalalalalalalalallalalalalala
                                </Typography>
                            </div>
                        )
                    }
                    <Typography onClick={toggleReadMore} className="read-or-hide" variant="h6" component="h2">
                        {isReadMore ? "...read more" : " show less"}
                    </Typography> */}
                </AboutMeContainer>
            </Tilt>
        </motion.div>
    );
};

const AboutMeContainer = styled(Grid)`
    && {
        text-indent: 4em;
        text-align: justify;
        text-justify: inter-word;
        position: relative;

        border-radius: 0px;
        //box-shadow: 0px 10px 10px 1px #909090;
        /* box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0); */

        background: var(--secondary-color);

        box-shadow: -4px -4px 0px 0px rgba(var(--rgb-secondary-color), 0.4),
            -7px -7px 0px 0px rgba(var(--rgb-secondary-color), 0.17),
            0px 10px 13px -7px rgba(var(--rgb-secondary-color), 1);
        color: #fff;
        padding: 2rem;
        margin-top: 3rem;
        transition: 0.6s;

        /* filter: blur(1px) contrast(0.5) brightness(1.5) drop-shadow(2px 4px 6px var(--primary-color)); */
    }
    &&:hover {
        /* color: var(--secondary-color); */
        background: var(--primary-color);
        box-shadow: -4px -4px 0px 0px rgba(var(--rgb-primary-color), 0.4),
            -7px -7px 0px 0px rgba(var(--rgb-primary-color), 0.17),
            0px 10px 13px -7px rgba(var(--rgb-primary-color), 1);
        /* filter: none; */
    }

    &&:hover .read-or-hide {
        color: var(--secondary-color);
    }
    && .read-or-hide {
        color: var(--primary-color);
        cursor: pointer;
    }

    && .MuiTypography-root {
        /* font-size: 1.1rem; */
        font-weight: 400;
        font-family: var(--portfolio-font);
        letter-spacing: 1px;
    }
    &&:hover .MuiTypography-root {
        /* font-weight: 900; */
        filter: ${({ darktheme }) =>
            darktheme === `true`
                ? "none"
                : `drop-shadow(1px 2px 3px var(--secondary-color))`};
    }
`;
