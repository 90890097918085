import React, { useEffect, useState } from 'react'

//import { Document, Page } from 'react-pdf'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { pdfjs } from 'react-pdf'




import { Typography } from '@mui/material';
import styled from 'styled-components';

export const PDFViewer = (props) => {

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages)
        setPageNumber(1)
    }

    const goToPrevPage = () => {
        setPageNumber((prevPageNumber) => prevPageNumber - 1 )
    }

    const goToNextPage = () =>{
        setPageNumber((prevPageNumber) => prevPageNumber + 1 )
    }

    useEffect(()=>{
        //pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js'
        pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`
        //pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
    },[])


    return (

        <div>
            <nav>
                {
                    pageNumber && pageNumber>1 &&
                    ( <button onClick={goToPrevPage}>Prev</button> )
                }
                {
                    pageNumber && pageNumber<numPages &&
                    ( <button onClick={goToNextPage}>Next</button> )
                }


            </nav>

            <PdfContainer >
                <Document
                    file={props.url}
                    onLoadSuccess={onDocumentLoadSuccess}
                    // onLoadError={console.error}
                >
                    <Page pageNumber={pageNumber}  />
                </Document>
            </PdfContainer>

            <Typography align="center">
                Page {pageNumber} of {numPages}
            </Typography>
        </div>

    )
}

const PdfContainer = styled.div`
    & .react-pdf__Page__annotations.annotationLayer {
        /* height: 0px; */
    }
`
