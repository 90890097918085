import React from "react";

// ################################     Mui Icons   ################################
import GitHubIcon from "@mui/icons-material/GitHub";
// import YouTubeIcon from '@mui/icons-material/YouTube'
// import SmartDisplayIcon from '@mui/icons-material/SmartDisplay'
import LanguageIcon from "@mui/icons-material/Language";
import { ReactComponent as AmazonIcon } from "@/assets/black-svg/amazon.svg";
import { ReactComponent as PlayStorIcon } from "@/assets/black-svg/playstore.svg";

// ################################     NUPAHealth   ################################
import NUPALogo from "@/assets/projects/NUPA-Health/NUPA Logo.svg";
import NUPAImage from "@/assets/projects/NUPA-Health/NUPA-Health.png";
import NUPA_screenshot_00 from "@/assets/projects/NUPA-Health/screen_0.jpg";
import NUPA_screenshot_01 from "@/assets/projects/NUPA-Health/screen_1.jpg";
import NUPA_screenshot_02 from "@/assets/projects/NUPA-Health/screen_2.jpg";
import NUPA_screenshot_03 from "@/assets/projects/NUPA-Health/screen_3.jpg";
import NUPA_screenshot_04 from "@/assets/projects/NUPA-Health/screen_4.jpg";
import NUPA_screenshot_05 from "@/assets/projects/NUPA-Health/screen_5.jpg";
import NUPA_screenshot_06 from "@/assets/projects/NUPA-Health/screen_6.jpg";
import NUPA_screenshot_07 from "@/assets/projects/NUPA-Health/screen_7.jpg";
import NUPA_screenshot_08 from "@/assets/projects/NUPA-Health/screen_8.jpg";
import NUPA_screenshot_09 from "@/assets/projects/NUPA-Health/screen_9.jpg";
import NUPA_screenshot_10 from "@/assets/projects/NUPA-Health/screen_10.jpg";
import NUPA_screenshot_11 from "@/assets/projects/NUPA-Health/screen_11.jpg";
import NUPA_screenshot_12 from "@/assets/projects/NUPA-Health/screen_12.jpg";
import NUPA_screenshot_13 from "@/assets/projects/NUPA-Health/screen_13.jpg";
import NUPA_screenshot_14 from "@/assets/projects/NUPA-Health/screen_14.jpg";
import NUPA_screenshot_15 from "@/assets/projects/NUPA-Health/screen_15.jpg";
import NUPA_screenshot_16 from "@/assets/projects/NUPA-Health/screen_16.jpg";
import NUPA_screenshot_17 from "@/assets/projects/NUPA-Health/screen_17.jpg";
import NUPA_screenshot_18 from "@/assets/projects/NUPA-Health/screen_18.jpg";
import NUPA_screenshot_19 from "@/assets/projects/NUPA-Health/screen_19.jpg";

// ################################     Digitso   ################################
import DigitsoLogo from "@/assets/projects/Digitso/logo4N_512x512.png";
import DigitsoImage from "@/assets/projects/Digitso/Feature-graphic.jpg";
import Digitso_screenshot_01 from "@/assets/projects/Digitso/screen_1.png";
import Digitso_screenshot_02 from "@/assets/projects/Digitso/screen_2.png";
import Digitso_screenshot_03 from "@/assets/projects/Digitso/screen_3.png";
import Digitso_screenshot_04 from "@/assets/projects/Digitso/screen_4.png";
import Digitso_screenshot_05 from "@/assets/projects/Digitso/screen_5.png";
import Digitso_screenshot_06 from "@/assets/projects/Digitso/screen_6.png";
import Digitso_screenshot_07 from "@/assets/projects/Digitso/screen_7.png";
import Digitso_screenshot_08 from "@/assets/projects/Digitso/screen_8.png";
import Digitso_screenshot_09 from "@/assets/projects/Digitso/screen_9.png";
import Digitso_screenshot_10 from "@/assets/projects/Digitso/screen_10.png";
import Digitso_screenshot_11 from "@/assets/projects/Digitso/screen_11.png";
import Digitso_screenshot_12 from "@/assets/projects/Digitso/screen_12.png";

// ################################     FitNation   ################################
import FitNationLogo from "@/assets/projects/FitNation/logo128x128.png";
import FitNationImage from "@/assets/projects/FitNation/full-logo.png";
import FitNation_screenshot_01 from "@/assets/projects/FitNation/screen_1.jpg";
import FitNation_screenshot_02 from "@/assets/projects/FitNation/screen_2.jpg";
import FitNation_screenshot_03 from "@/assets/projects/FitNation/screen_3.jpg";
import FitNation_screenshot_04 from "@/assets/projects/FitNation/screen_4.jpg";
import FitNation_screenshot_05 from "@/assets/projects/FitNation/screen_5.jpg";
import FitNation_screenshot_06 from "@/assets/projects/FitNation/screen_6.jpg";
import FitNation_screenshot_07 from "@/assets/projects/FitNation/screen_7.jpg";
import FitNation_screenshot_08 from "@/assets/projects/FitNation/screen_8.jpg";
import FitNation_screenshot_09 from "@/assets/projects/FitNation/screen_9.jpg";
import FitNation_screenshot_10 from "@/assets/projects/FitNation/screen_10.jpg";

// ################################     OperaPeinture   ################################
import OperaPeintureLogo from "@/assets/projects/OperaPeinture/logo4N_128x128.png";
import OperaPeintureImage from "@/assets/projects/OperaPeinture/Feature-graphic.jpg";
import OperaPeinture_screenshot_01 from "@/assets/projects/OperaPeinture/screen_1.jpg";
import OperaPeinture_screenshot_02 from "@/assets/projects/OperaPeinture/screen_2.jpg";
import OperaPeinture_screenshot_03 from "@/assets/projects/OperaPeinture/screen_3.jpg";
import OperaPeinture_screenshot_04 from "@/assets/projects/OperaPeinture/screen_4.jpg";
import OperaPeinture_screenshot_05 from "@/assets/projects/OperaPeinture/screen_5.jpg";
import OperaPeinture_screenshot_06 from "@/assets/projects/OperaPeinture/screen_6.jpg";
import OperaPeinture_screenshot_07 from "@/assets/projects/OperaPeinture/screen_7.jpg";
import OperaPeinture_screenshot_08 from "@/assets/projects/OperaPeinture/screen_8.jpg";
import OperaPeinture_screenshot_09 from "@/assets/projects/OperaPeinture/screen_9.jpg";
import OperaPeinture_screenshot_10 from "@/assets/projects/OperaPeinture/screen_10.jpg";
import OperaPeinture_screenshot_11 from "@/assets/projects/OperaPeinture/screen_11.jpg";
import OperaPeinture_screenshot_12 from "@/assets/projects/OperaPeinture/screen_12.jpg";
import OperaPeinture_screenshot_13 from "@/assets/projects/OperaPeinture/screen_13.jpg";

// ################################     BarbersIncomes   ################################
import BarbersIncomesLogo from "@/assets/projects/BarbersIncomes/logo2-128.png";
import BarbersIncomesImage from "@/assets/projects/BarbersIncomes/Feature-graphic.png";
import BarbersIncomes_screenshot_01 from "@/assets/projects/BarbersIncomes/screen_1.png";
import BarbersIncomes_screenshot_02 from "@/assets/projects/BarbersIncomes/screen_2.png";
import BarbersIncomes_screenshot_03 from "@/assets/projects/BarbersIncomes/screen_3.png";
import BarbersIncomes_screenshot_04 from "@/assets/projects/BarbersIncomes/screen_4.png";
import BarbersIncomes_screenshot_05 from "@/assets/projects/BarbersIncomes/screen_5.png";
import BarbersIncomes_screenshot_06 from "@/assets/projects/BarbersIncomes/screen_6.png";
import BarbersIncomes_screenshot_07 from "@/assets/projects/BarbersIncomes/screen_7.png";
import BarbersIncomes_screenshot_08 from "@/assets/projects/BarbersIncomes/screen_8.png";
import BarbersIncomes_screenshot_09 from "@/assets/projects/BarbersIncomes/screen_9.png";
import BarbersIncomes_screenshot_10 from "@/assets/projects/BarbersIncomes/screen_10.png";
import BarbersIncomes_screenshot_11 from "@/assets/projects/BarbersIncomes/screen_11.png";

// ################################     Parole   ################################
import ParoleLogo from "@/assets/projects/Parole/logo-128x128.png";
import ParoleImage from "@/assets/projects/Parole/logo.png";
import Parole_screenshot_01 from "@/assets/projects/Parole/screen_1.jpg";
import Parole_screenshot_02 from "@/assets/projects/Parole/screen_2.jpg";
import Parole_screenshot_03 from "@/assets/projects/Parole/screen_3.jpg";
import Parole_screenshot_04 from "@/assets/projects/Parole/screen_4.jpg";
import Parole_screenshot_05 from "@/assets/projects/Parole/screen_5.jpg";
// import Parole_screenshot_06 from "@/assets/projects/Parole/screen_6.jpg"
// import Parole_screenshot_07 from "@/assets/projects/Parole/screen_7.jpg"
// import Parole_screenshot_08 from "@/assets/projects/Parole/screen_8.jpg"

// ################################     Time Detector   ################################
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TimeDetectorImage from "@/assets/projects/TimeDetector/tutorial.png";
// import animatedTimeDetector from "@/assets/projects/TimeDetector/time-detector.gif"
import TimeDetector_screenshot_01 from "@/assets/projects/TimeDetector/screen_1.jpg";
import TimeDetector_screenshot_02 from "@/assets/projects/TimeDetector/screen_2.jpg";
import TimeDetector_screenshot_03 from "@/assets/projects/TimeDetector/screen_3.jpg";
import TimeDetector_screenshot_04 from "@/assets/projects/TimeDetector/screen_4.jpg";
import TimeDetector_screenshot_05 from "@/assets/projects/TimeDetector/screen_5.jpg";
import TimeDetector_screenshot_06 from "@/assets/projects/TimeDetector/screen_6.jpg";
import TimeDetector_screenshot_07 from "@/assets/projects/TimeDetector/screen_7.jpg";
import TimeDetector_screenshot_08 from "@/assets/projects/TimeDetector/screen_8.jpg";
import TimeDetector_screenshot_09 from "@/assets/projects/TimeDetector/screen_9.jpg";
import TimeDetector_screenshot_10 from "@/assets/projects/TimeDetector/screen_10.jpg";
import TimeDetector_screenshot_11 from "@/assets/projects/TimeDetector/screen_11.jpg";
import TimeDetector_screenshot_12 from "@/assets/projects/TimeDetector/screen_12.jpg";

// ################################     Tuber   ################################
// import TuberLogo from "@/assets/projects/Tuber/logo.svg"
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
// import TuberImage from "@/assets/projects/Tuber/screen_1.jpg"
import Tuber_screenshot_01 from "@/assets/projects/Tuber/screen_1.jpg";
import Tuber_screenshot_02 from "@/assets/projects/Tuber/screen_2.jpg";
import Tuber_screenshot_03 from "@/assets/projects/Tuber/screen_3.jpg";
import Tuber_screenshot_04 from "@/assets/projects/Tuber/screen_4.jpg";
import Tuber_screenshot_05 from "@/assets/projects/Tuber/screen_5.jpg";
import Tuber_screenshot_06 from "@/assets/projects/Tuber/screen_6.png";
import Tuber_screenshot_07 from "@/assets/projects/Tuber/screen_7.png";
import Tuber_screenshot_08 from "@/assets/projects/Tuber/screen_8.png";
import Tuber_screenshot_09 from "@/assets/projects/Tuber/screen_9.png";

// ################################     BackgroundRemove   ################################
// import BackgroundRemoveLogo from "@/assets/projects/BackgroundRemove/logo.svg"
import WallpaperIcon from "@mui/icons-material/Wallpaper";
import BackgroundRemoveImage from "@/assets/projects/BackgroundRemove/images_videos.jpg";
// import BackgroundRemove_screenshot_01 from "@/assets/projects/BackgroundRemove/screen_1.jpg"
// import BackgroundRemove_screenshot_02 from "@/assets/projects/BackgroundRemove/screen_2.jpg"
import BackgroundRemove_screenshot_03 from "@/assets/projects/BackgroundRemove/screen_3.jpg";
// import BackgroundRemove_screenshot_04 from "@/assets/projects/BackgroundRemove/screen_4.jpg"
// import BackgroundRemove_screenshot_05 from "@/assets/projects/BackgroundRemove/screen_5.jpg"
import BackgroundRemove_screenshot_06 from "@/assets/projects/BackgroundRemove/screen_6.jpg";

// ################################     BooksLib   ################################
// import BooksLibLogo from "@/assets/projects/BooksLib/logo.svg"
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
// import BooksLibImage from "@/assets/projects/BooksLib/home.jpg"
import BooksLib_screenshot_01 from "@/assets/projects/BooksLib/screen_1.jpg";
import BooksLib_screenshot_02 from "@/assets/projects/BooksLib/screen_2.jpg";
import BooksLib_screenshot_03 from "@/assets/projects/BooksLib/screen_3.jpg";
import BooksLib_screenshot_04 from "@/assets/projects/BooksLib/screen_4.jpg";
import BooksLib_screenshot_05 from "@/assets/projects/BooksLib/screen_5.jpg";
import BooksLib_screenshot_06 from "@/assets/projects/BooksLib/screen_6.jpg";
import BooksLib_screenshot_07 from "@/assets/projects/BooksLib/screen_7.jpg";
import BooksLib_screenshot_08 from "@/assets/projects/BooksLib/screen_8.jpg";
import BooksLib_screenshot_09 from "@/assets/projects/BooksLib/screen_9.jpg";
import BooksLib_screenshot_10 from "@/assets/projects/BooksLib/screen_10.jpg";
import BooksLib_screenshot_11 from "@/assets/projects/BooksLib/screen_11.jpg";
import BooksLib_screenshot_12 from "@/assets/projects/BooksLib/screen_12.jpg";

// ################################     DeepConsultation   ################################
// import DeepConsultationLogo from "@/assets/projects/DeepConsultation/logo.svg"
import BusinessIcon from "@mui/icons-material/Business";
// import DeepConsultationImage from "@/assets/projects/DeepConsultation/screen_1.jpg"
import DeepConsultation_screenshot_01 from "@/assets/projects/DeepConsultation/screen_1.jpg";
import DeepConsultation_screenshot_02 from "@/assets/projects/DeepConsultation/screen_2.jpg";
import DeepConsultation_screenshot_03 from "@/assets/projects/DeepConsultation/screen_3.jpg";
import DeepConsultation_screenshot_04 from "@/assets/projects/DeepConsultation/screen_4.jpg";
import DeepConsultation_screenshot_05 from "@/assets/projects/DeepConsultation/screen_5.jpg";
import DeepConsultation_screenshot_06 from "@/assets/projects/DeepConsultation/screen_6.jpg";
// import DeepConsultation_screenshot_07 from "@/assets/projects/DeepConsultation/screen_7.jpg"
import DeepConsultation_screenshot_08 from "@/assets/projects/DeepConsultation/screen_8.jpg";
import DeepConsultation_screenshot_09 from "@/assets/projects/DeepConsultation/screen_9.jpg";

// ################################     Watermarking-Medical-Image   ################################
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import WatermarkingMedicalImage_screenshot_01 from "@/assets/projects/Watermarking-Medical-Image/INSERT.jpg";
import WatermarkingMedicalImage_screenshot_02 from "@/assets/projects/Watermarking-Medical-Image/insertion.jpg";
import WatermarkingMedicalImage_screenshot_03 from "@/assets/projects/Watermarking-Medical-Image/EXTRACT.jpg";
import WatermarkingMedicalImage_screenshot_04 from "@/assets/projects/Watermarking-Medical-Image/extraction.jpg";

const ProjectsList = [
    {
        title: "Opéra Peinture",
        date: new Date("09/05/2021"),
        logo: OperaPeintureLogo,
        image: OperaPeintureImage,
        shortDescription: `A walls painting simulator, developed for the benefit of a painting company called "Opéra Peinture".`,
        fullDescription: [
            `An Android app for benefit of paint sales company.`,
            `the application enables users to simulate the painting of walls with raw colors and textures.`,
            `As a result, the application has increased the company's paint sales revenue by 20%.`,
            `Garnered 4.6/5-star rating on PlayStore.`,
        ],
        usedSkills: ["Java", "HTML5", "CSS3", "JavaScript", "JQuery"],
        screenshotList: [
            OperaPeinture_screenshot_01,
            OperaPeinture_screenshot_02,
            OperaPeinture_screenshot_06,
            OperaPeinture_screenshot_07,
            OperaPeinture_screenshot_08,
            OperaPeinture_screenshot_09,
            OperaPeinture_screenshot_10,
            OperaPeinture_screenshot_03,
            OperaPeinture_screenshot_04,
            OperaPeinture_screenshot_05,
            OperaPeinture_screenshot_11,
            OperaPeinture_screenshot_12,
            OperaPeinture_screenshot_13,
        ],
        links: [
            {
                text: "Google Play Store",
                url: "https://play.google.com/store/apps/details?id=org.qtproject.opera_peinture",
                icon: <PlayStorIcon />,
            },
            // { text: 'Devloker/OperaPainter', url: 'https://github.com/DEVLOKER/OperaPainter', icon: <GitHubIcon/> },
            {
                text: "Website",
                url: "https://operapeinture.com/simulateur-opera",
                icon: <LanguageIcon />,
            },
        ],
        notes: "",
    },

    {
        title: "NUPA Health",
        date: new Date("11/08/2023"),
        logo: NUPALogo,
        image: NUPAImage,
        shortDescription: `A Web application, built using .NET Core, for tracking patients health, managing their appointments.`,
        fullDescription: [
            `A Web application, represents a comprehensive solution for healthcare providers and patients alike. Designed to streamline the management of patient health, appointments, and medical invoices`,
            `allows healthcare providers to efficiently monitor and manage patient health records: 
                MEWS (Respiration Rate, Blood Oxygen, Heart Rate, Blood Pressure, Blood Pressure, Stress, Stress, Steps, Sleep Duration), 
                M-CDRI (smoking, Alcohol Use, Nutrition Meat Intake, Waist to hip ratio), 
                DASS-21 (depression, anxeity, stress)`,
            `Manage caregivers (Physicians and Nurses)`,
            `Schedule and track patient appointments, reducing administrative workload and ensuring that healthcare providers can focus on delivering excellent care.`,
            `Simplify the billing process by generating and managing medical invoices`,
        ],
        usedSkills: [
            "HTML5",
            "CSS3",
            "Bootstrap",
            "JavaScript",
            "JQuery",
            ".NET Core",
        ],
        screenshotList: [
            NUPA_screenshot_00,
            NUPA_screenshot_01,
            NUPA_screenshot_02,
            NUPA_screenshot_03,
            NUPA_screenshot_04,
            NUPA_screenshot_05,
            NUPA_screenshot_06,
            NUPA_screenshot_07,
            NUPA_screenshot_08,
            NUPA_screenshot_09,
            NUPA_screenshot_10,
            NUPA_screenshot_11,
            NUPA_screenshot_12,
            NUPA_screenshot_13,
            NUPA_screenshot_14,
            NUPA_screenshot_15,
            NUPA_screenshot_16,
            NUPA_screenshot_17,
            NUPA_screenshot_18,
            NUPA_screenshot_19,
        ],
        links: [
            // { text: 'FitNation DZ', url: 'https://fitnation.dz', icon: <LanguageIcon/>},
            // { text: 'YouTubLink', url: '#', icon: <YouTubeIcon/> }
        ],
        notes: "",
    },

    {
        title: "FitNation",
        date: new Date("06/01/2021"),
        logo: FitNationLogo,
        image: FitNationImage,
        shortDescription: `A desktop application designed specifically for coaches, providing them with a comprehensive solution for managing the incomes of gym subscribers and monitoring their sports and nutrition programs.`,
        fullDescription: [
            `A desktop application designed specifically for coaches, providing them with a comprehensive 
            solution for managing the incomes of gym subscribers and monitoring their sports and nutrition programs.`,
            `With "FitNation", coaches can easily track their clients' progress and adjust their programs accordingly, 
            ensuring optimal results.`,
            `"FitNation" is both user-friendly and efficient, allowing coaches to focus on what they do best and 
            helping their clients achieve their fitness goals.`,
        ],
        usedSkills: [
            "HTML5",
            "CSS3",
            "Bootstrap",
            "JavaScript",
            "JQuery",
            "NodeJs",
            "Electron",
            "MySQL",
        ],
        screenshotList: [
            FitNation_screenshot_01,
            FitNation_screenshot_02,
            FitNation_screenshot_03,
            FitNation_screenshot_04,
            FitNation_screenshot_05,
            FitNation_screenshot_06,
            FitNation_screenshot_07,
            FitNation_screenshot_08,
            FitNation_screenshot_09,
            FitNation_screenshot_10,
        ],
        links: [
            {
                text: "FitNation DZ",
                url: "https://script-trealther.onrender.com/View/pages/dashboard.html",
                icon: <LanguageIcon />,
            },
            // { text: 'YouTubLink', url: '#', icon: <YouTubeIcon/> }
        ],
        notes: "",
    },

    {
        title: "Digitso Game",
        date: new Date("03/29/2022"),
        logo: DigitsoLogo,
        image: DigitsoImage,
        shortDescription: `Free android puzzle game for enhancing logical thinking skills in a fun and entertaining way.`,
        fullDescription: [
            `Digitso is an innovative Android puzzle game that challenges players to correctly guess a number composed of four distinct digits.`,
            `With each guess, players receive feedback on how many digits they have correctly identified, as well as how many digits are in the correct position.`,
            `Additionally, it allows players to compete against a robot opponent with varying difficulty levels, and also it features playing in Bluetooth mode.`,
            `To aid players in their quest, Digitso offers video ad support (through applovin and unity ads).`,
            `These optional ads provide players with helpful tips and tricks, allowing them to improve their chances of success.`,
            `With its engaging game-play and intuitive interface, witch makes Digitso one of the best puzzle game for players of all skill levels.`,
        ],
        usedSkills: ["SDK", "Java", "Framework7", "JavaScript"],
        screenshotList: [
            Digitso_screenshot_01,
            Digitso_screenshot_02,
            Digitso_screenshot_03,
            Digitso_screenshot_04,
            Digitso_screenshot_05,
            Digitso_screenshot_06,
            Digitso_screenshot_07,
            Digitso_screenshot_08,
            Digitso_screenshot_09,
            Digitso_screenshot_10,
            Digitso_screenshot_11,
            Digitso_screenshot_12,
        ],
        links: [
            {
                text: "Amazon appstore",
                url: "http://www.amazon.com/gp/mas/dl/android?p=dev.loker.games.digitso",
                icon: <AmazonIcon />,
            },
            // { text: 'Devloker/Digitso', url: 'https://github.com/DEVLOKER/Digitso', icon: <GitHubIcon/>},
        ],
        notes: "",
    },

    {
        title: "Deep Consultation",
        date: new Date("15/02/2023"),
        logo: BusinessIcon,
        image: null,
        shortDescription: `MERN application that provides a comprehensive solution for managing consultations.`,
        fullDescription: [
            `MERN application that provides a comprehensive solution for managing consultations, it is designed 
            to streamline the consultation process and provide clients with a seamless user experience`,
            `With Deep Consultation, clients can easily submit their consultation requests and attach relevant 
            files in a variety of formats.`,
            `The application features enable consultation managers to quickly and efficiently process these requests, 
            providing clients with prompt and effective solutions.`,
            `Once the consultation has been processed, clients can easily download any attachments associated 
            with their consultation, enabling them to stay informed and up-to-date on the progress of their requests.`,
        ],
        usedSkills: ["mongoDB", "Express", "React", "NodeJs"],
        screenshotList: [
            DeepConsultation_screenshot_01,
            DeepConsultation_screenshot_02,
            DeepConsultation_screenshot_03,
            DeepConsultation_screenshot_04,
            DeepConsultation_screenshot_05,
            DeepConsultation_screenshot_06,
            DeepConsultation_screenshot_08,
            DeepConsultation_screenshot_09,
            // DeepConsultation_screenshot_01, DeepConsultation_screenshot_02, DeepConsultation_screenshot_03, DeepConsultation_screenshot_04, DeepConsultation_screenshot_05, DeepConsultation_screenshot_06, DeepConsultation_screenshot_07, DeepConsultation_screenshot_08, DeepConsultation_screenshot_09
        ],
        links: [
            {
                text: "Devloker/Deep-Consultation",
                url: "https://github.com/DEVLOKER/Deep-Consultation",
                icon: <GitHubIcon />,
            },
        ],
        notes: "",
    },

    {
        title: "Watermarking Medical Image",
        date: new Date("06/06/2023"),
        logo: BrandingWatermarkIcon,
        image: null,
        shortDescription: `A Python application implements an approach for watermarking of medical images based on the least significant bits (LSBs) .`,
        fullDescription: [
            `A Python application implements an approach for watermarking of medical images based on the least significant bits (LSBs).`,
            `It is intended to insert a encrypted set of data in a medical image, while the data specific to the patient and his diagnostic.`,
            `We use "Opencv" for detecting the important feature points using Harris corner detector.`,
            `And "Crypto" for encrypting and decrypting patient data using a secret key.`,
            `And "reedmuller" algorithm to verify the conformity of the obtained message and correct the possible alterations if they exist.`,
        ],
        usedSkills: ["Python", "HTML5", "CSS3", "JavaScript", "JQuery"],
        screenshotList: [
            WatermarkingMedicalImage_screenshot_01,
            WatermarkingMedicalImage_screenshot_02,
            WatermarkingMedicalImage_screenshot_03,
            WatermarkingMedicalImage_screenshot_04,
        ],
        links: [
            {
                text: "Devloker/Watermarking-Medical-Image",
                url: "https://github.com/DEVLOKER/Watermarking-Medical-Image",
                icon: <GitHubIcon />,
            },
        ],
        notes: "",
    },

    {
        title: "Books Lib",
        date: new Date("01/05/2023"),
        logo: ImportContactsIcon,
        image: null, //BooksLibImage,
        shortDescription: `Web application for buying used books, developed with Spring Boot and React.`,
        fullDescription: [
            `A solution in the realm of online book buying and selling.`,
            `Whether as a book enthusiast looking to add to your personal library, or a seller looking to connect with a wider audience.`,
            `Users can browse through a vast collection of used books, and can easily publish, favorite, rate, or report any book.`,
            `The application also includes a chat functionality, which enables users to communicate with the seller to negotiate the book price.`,
            `In addition, the platform includes additional administrative features, allowing the admin to manage book categories and user reports.`,
        ],
        usedSkills: ["MySQL", "Java", "SpringBoot", "React", "Bootstrap"],
        screenshotList: [
            BooksLib_screenshot_01,
            BooksLib_screenshot_02,
            BooksLib_screenshot_03,
            BooksLib_screenshot_04,
            BooksLib_screenshot_05,
            BooksLib_screenshot_06,
            BooksLib_screenshot_07,
            BooksLib_screenshot_08,
            BooksLib_screenshot_09,
            BooksLib_screenshot_10,
            BooksLib_screenshot_11,
            BooksLib_screenshot_12,
        ],
        links: [
            {
                text: "Devloker/Books-Lib",
                url: "https://github.com/DEVLOKER/Books-Lib",
                icon: <GitHubIcon />,
            },
        ],
        notes: "",
    },

    {
        title: "Time Detector",
        date: new Date("12/01/2020"),
        logo: AccessTimeIcon,
        image: TimeDetectorImage,
        shortDescription: `Python program for calculating time of analog clock image based on clockwise angles.`,
        fullDescription: [
            `Time Detector is an advanced Python program designed to accurately calculate the time of an analog clock image based on clockwise angles. Developed with a focus on precision and efficiency.`,
            `The program utilizes an algorithm to automatically detect the center of the analog clock and draw a circle around it.`,
            `Once the center has been established, The program proceeds to draw clockwise lines from the center of the clock. It takes into account the angle and the length of each clockwise line, with shorter lines indicating the hours and longer lines indicating the minutes.`,
            `Finally, the program determine the corresponding time according to the clockwise lines.`,
        ],
        usedSkills: ["Python"],
        screenshotList: [
            // animatedTimeDetector,
            TimeDetector_screenshot_01,
            TimeDetector_screenshot_02,
            TimeDetector_screenshot_03,
            TimeDetector_screenshot_04,
            TimeDetector_screenshot_05,
            TimeDetector_screenshot_06,
            TimeDetector_screenshot_07,
            TimeDetector_screenshot_08,
            TimeDetector_screenshot_09,
            TimeDetector_screenshot_10,
            TimeDetector_screenshot_11,
            TimeDetector_screenshot_12,
        ],
        links: [
            {
                text: "Devloker/Time-Detector",
                url: "https://github.com/DEVLOKER/Time-Detector",
                icon: <GitHubIcon />,
            },
        ],
        notes: "",
    },

    {
        title: "Tuber",
        date: new Date("10/17/2022"),
        logo: VideoLibraryIcon,
        image: null,
        shortDescription: `Web application designed to download YouTube videos and audio streams in various formats, along with captions in both text and XML formats if they are available.`,
        fullDescription: [
            `Web application designed to download YouTube videos and audio streams in various formats, along with captions in both text and XML formats if they are available.`,
            `With Tuber, users can easily download their favorite videos from YouTube with just a few clicks, without needing any third-party software or plugins and without ads too.`,
            `The ability to download entire playlists from YouTube will soon be added as a new feature in Tuber app.`,
        ],
        usedSkills: ["Python", "React"],
        screenshotList: [
            Tuber_screenshot_01,
            Tuber_screenshot_02,
            Tuber_screenshot_03,
            Tuber_screenshot_04,
            Tuber_screenshot_05,
            Tuber_screenshot_06,
            Tuber_screenshot_07,
            Tuber_screenshot_08,
            Tuber_screenshot_09,
        ],
        links: [
            {
                text: "Devloker/Tuber",
                url: "https://github.com/DEVLOKER/Tuber",
                icon: <GitHubIcon />,
            },
        ],
        notes: "",
    },

    // {
    //     title: 'Bg Remover',
    //     date: new Date('12/23/2022'),
    //     logo: WallpaperIcon,
    //     image: BackgroundRemoveImage,
    //     shortDescription: `Python application allows to remove multiple images & videos background, developed based on ModNet.`,
    //     fullDescription: [
    //         `Python application designed to remove backgrounds from multiple images and videos.`,
    //         `The application has been built using React and Python based on the ModNet project, which is a portrait matting solution (https://github.com/ZHKKKe/MODNet).`,
    //         `With Bg-Remover, users can effortlessly remove the background from any image and save the results in high-quality JPG and PNG formats.`,
    //         `Additionally, users can also remove the background from videos and save the results in MP4 and MWEB format.`,
    //         `Bg-Remover represents an ideal tool for photographers, video editors, and other professionals looking to remove backgrounds from their work.`
    //     ],
    //     usedSkills: ["Python"],
    //     screenshotList: [
    //         BackgroundRemove_screenshot_03, BackgroundRemove_screenshot_06
    //         // BackgroundRemove_screenshot_01, BackgroundRemove_screenshot_02, BackgroundRemove_screenshot_03, BackgroundRemove_screenshot_04, BackgroundRemove_screenshot_05, BackgroundRemove_screenshot_06,
    //     ],
    //     links: [
    //         { text: 'Devloker/BgRemover', url: 'https://github.com/DEVLOKER/BgRemover', icon: <GitHubIcon/>},
    //     ],
    //     notes: '',
    // },

    // {
    //     title: "Barbers Incomes",
    //     date: new Date("06/20/2022"),
    //     logo: BarbersIncomesLogo,
    //     image: BarbersIncomesImage,
    //     shortDescription: `An Android application designed for barbers to manage their daily incomes.`,
    //     fullDescription: [
    //         `An Android application designed for barbers to manage their daily incomes`,
    //         `It contains banner Ads (applovin).`,
    //         // `Available on Amazon Appstore since June 20, 2022.`,
    //     ],
    //     usedSkills: [
    //         "Java",
    //         "HTML5",
    //         "CSS3",
    //         "Framework7",
    //         "JavaScript",
    //         "JQuery",
    //     ],
    //     screenshotList: [
    //         BarbersIncomes_screenshot_01,
    //         BarbersIncomes_screenshot_02,
    //         BarbersIncomes_screenshot_03,
    //         BarbersIncomes_screenshot_04,
    //         BarbersIncomes_screenshot_05,
    //         BarbersIncomes_screenshot_06,
    //         BarbersIncomes_screenshot_07,
    //         BarbersIncomes_screenshot_08,
    //         BarbersIncomes_screenshot_09,
    //         BarbersIncomes_screenshot_10,
    //         BarbersIncomes_screenshot_11,
    //     ],
    //     links: [
    //         {
    //             text: "Amazon Appstore",
    //             url: "http://www.amazon.com/gp/mas/dl/android?p=dev.loker.apps.barbers_incomes",
    //             icon: <AmazonIcon />,
    //         },
    //     ],
    //     notes: "",
    // },

    // {
    //     title: 'Parole',
    //     date: new Date('06/06/2019'),
    //     logo: ParoleLogo,
    //     image: ParoleImage,
    //     shortDescription: `PHP Web app for managing client's real-time requests.`,
    //     fullDescription: [
    //         `Web app to simulate managing access of clients requests by an administrator.`,
    //         `The admin can (give/refuse/end) the access for only one client in time.`,
    //         `Here an example of management of requests to speak.`,
    //     ],
    //     usedSkills: ["HTML5", "CSS3", "Bootstrap", "JavaScript", "JQuery", "PHP", "MySQL"],
    //     screenshotList: [
    //         Parole_screenshot_01, Parole_screenshot_02, Parole_screenshot_03, Parole_screenshot_04, Parole_screenshot_05//, Parole_screenshot_06, Parole_screenshot_07, Parole_screenshot_08
    //     ],
    //     links: [
    //         { text: 'Devloker/Parole', url: 'https://github.com/DEVLOKER/Parole', icon: <GitHubIcon/>},
    //     ],
    //     notes: '',
    // },
];

export default ProjectsList;
