import testimonial_01 from "@/assets/upwork-testimonials/01.png";
import testimonial_02 from "@/assets/upwork-testimonials/02.png";
import testimonial_03 from "@/assets/upwork-testimonials/03.png";
import testimonial_04 from "@/assets/upwork-testimonials/04.png";
import testimonial_05 from "@/assets/upwork-testimonials/05.png";
import testimonial_06 from "@/assets/upwork-testimonials/06.png";
import testimonial_07 from "@/assets/upwork-testimonials/07.png";
import testimonial_08 from "@/assets/upwork-testimonials/08.png";
import testimonial_09 from "@/assets/upwork-testimonials/09.png";
import testimonial_10 from "@/assets/upwork-testimonials/10.png";
import testimonial_11 from "@/assets/upwork-testimonials/11.png";
import testimonial_12 from "@/assets/upwork-testimonials/12.png";
import testimonial_13 from "@/assets/upwork-testimonials/13.png";
import testimonial_14 from "@/assets/upwork-testimonials/14.png";
import testimonial_15 from "@/assets/upwork-testimonials/15.png";
import testimonial_16 from "@/assets/upwork-testimonials/16.png";
import testimonial_17 from "@/assets/upwork-testimonials/17.png";
import testimonial_18 from "@/assets/upwork-testimonials/18.png";
import testimonial_19 from "@/assets/upwork-testimonials/19.png";
import testimonial_20 from "@/assets/upwork-testimonials/20.png";
import testimonial_21 from "@/assets/upwork-testimonials/21.png";
import testimonial_22 from "@/assets/upwork-testimonials/22.png";

export default [
    testimonial_01,
    testimonial_02,
    testimonial_03,
    testimonial_04,
    testimonial_05,
    testimonial_06,
    testimonial_07,
    testimonial_08,
    testimonial_09,
    testimonial_10,
    testimonial_11,
    testimonial_12,
    testimonial_13,
    testimonial_14,
    testimonial_15,
    testimonial_16,
    testimonial_17,
    testimonial_18,
    testimonial_19,
    testimonial_20,
    testimonial_21,
    testimonial_22
].reverse();
