import { configureStore } from "@reduxjs/toolkit";
import { themeSlice } from "./redux.theme";
import { ElementsSlice } from "./redux.elements";
import { LoadingSlice } from "./redux.loading";


export const portfolioStore = configureStore({
    reducer: {
        theme : themeSlice.reducer,
        elements: ElementsSlice.reducer,
        loading: LoadingSlice.reducer,
    },
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    //         serializableCheck: false,
    //     }),

})