import { Box, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import React from 'react'
import styled from 'styled-components';

export const DeveloperName = (props) => {
    // filter, primaryColor, secondaryColor
    // style={{filter : darkTheme? 'invert(1) drop-shadow(2px 4px 6px #000)': 'invert(0)'}}
    const { darkTheme } = useSelector(state=> state.theme)
    return (
        <Container className='developerName' style={props?.style} theme={darkTheme} animate={props?.animate}   >
            <Box >
                <Typography variant="h2" component="h2" >
                    Dev
                </Typography>
            </Box>
            <Box >
                <Typography variant="h2" component="h2" >
                    Loker
                </Typography>
            </Box>
        </Container>
    )
}


const Container = styled(Box)`
    & {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        position: relative;
        box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
        /* box-shadow: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0); */
        border-radius: 20px;
    }

    & .MuiBox-root:first-child {
        background: var(--primary-color);
        padding: 2px 22px 2px 30px;
    }
    & .MuiBox-root:last-child {
        background: var(--secondary-color);
        padding: 2px 30px 2px 12px;
        border-radius: 20px 0px 0px 20px;
        margin-left: -20px;
    }

    & .MuiTypography-root {
        font-weight: bolder;
        color: #fff;
        animation-duration: 2s;
        /* font-family: fantasy; */
        font-family: var(--portfolio-font);
        letter-spacing: 4px;
        padding: 10px 1px;
        text-shadow: ${({theme})=> theme? 'none' : '0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15)' };
    }
    &::after {
        content: 'Full-Stack Developer';
        position: absolute;
        /* width: 100%; */
        height: 100%;
        top: 0;
        left: 0;
        background: var(--primary-color);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: x-large;
        font-weight: bolder;
        color: #fff;
        font-family:  var(--portfolio-font);
        letter-spacing: 4px;
        visibility: hidden;
        width: 0%;
        opacity: 0;
        transition: all 0.5s ease;
        cursor: pointer;
    }

    &:hover::after{
        visibility: visible;
        animation-direction: reverse;
        width: 100%;
        opacity: 1;
    }
    
`;