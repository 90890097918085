import React from "react";
import styled from "styled-components";
import { Badge, IconButton, Tooltip } from "@mui/material";

import { motion } from "framer-motion";

const iconsVariants = {
    hidden: { opacity: 0, x: 0, y: -100 },
    visible: (i) => {
        return {
            opacity: 1,
            x: 0,
            y: 0,
            rotate: [20, -20, 20, -20, 0],
            scale: [1, 1.5, 1],
            transition: {
                times: [0.0, 0.5, 1],
                // duration: 0.2,
                // type: 'tween',
                // ease: 'easeOut',
                type: "spring",
                // bounce:0.3,
                // stiffness: 330,
                // damping: 30,
                // mass: 0.5,
                // delay: (i*0.2), // (4*0.8)-
            },
        };
    },
    hover: {
        rotateZ: [0, 0, -15, 10, -10, 6, -4, 0, 0],
        transition: {
            duration: 0.8,
        },
    },
};

export const CustomIcon = ({
    label,
    icon,
    link,
    target,
    action,
    index,
    badge,
}) => {
    //initial='hidden' animate='visible' whileHover='hover'
    return (
        <motion.div variants={iconsVariants} custom={index}>
            <Tooltip title={label}>
                <StyledIcon index={index} aria-label={label} onClick={action}>
                    <Badge
                        badgeContent={badge ? badge : <></>}
                        // overlap="circular"
                        // invisible={}
                        color="default"
                    >
                        <a href={link} target={target}>
                            {icon}
                        </a>
                    </Badge>
                </StyledIcon>
            </Tooltip>
        </motion.div>
    );
};

const StyledIcon = styled(IconButton)`
    && {
        transition: 1s;
        background: var(--primary-color);
        padding: 1px;
        margin: 8px;
        line-height: 14px;
        border-radius: 0px;
        box-shadow: -4px -4px 0px 0px rgba(var(--rgb-secondary-color), 0.4),
            -7px -7px 0px 0px rgba(var(--rgb-secondary-color), 0.17),
            0px 10px 13px -7px rgba(var(--rgb-secondary-color), 1);
    }
    &&:nth-child(2n + 1),
    &&:nth-child(2n) {
        background: var(--secondary-color);
    }
    &&:hover {
        background-color: var(--primary-color);
        box-shadow: -4px -4px 0px 0px rgba(var(--rgb-primary-color), 0.4),
            -7px -7px 0px 0px rgba(var(--rgb-primary-color), 0.17),
            0px 10px 13px -7px rgba(var(--rgb-primary-color), 1);
    }
    &&:hover {
        animation: wiggle 1.5s ease-out 1;
    }
    && svg {
        width: 35px;
        height: 35px;
        fill: #ffffff;
    }

    @keyframes wiggle {
        0%,
        7% {
            transform: rotateZ(0);
        }
        15% {
            transform: rotateZ(-15deg);
        }
        20% {
            transform: rotateZ(10deg);
        }
        25% {
            transform: rotateZ(-10deg);
        }
        30% {
            transform: rotateZ(6deg);
        }
        35% {
            transform: rotateZ(-4deg);
        }
        40%,
        100% {
            transform: rotateZ(0);
        }
    }
`;
