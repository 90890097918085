import React, { useEffect, useRef } from "react";
import styled from "styled-components";

import HomeIcon from "@mui/icons-material/Home";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
// import DownhillSkiingIcon from '@mui/icons-material/DownhillSkiing';
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import AssistantIcon from "@mui/icons-material/Assistant";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { useDispatch, useSelector, batch } from "react-redux";

import { toggleTheme, toggleInvertFilter } from "@/context/redux.theme";
import { Fade, useScrollTrigger } from "@mui/material";
import { StyledFab } from "@/components/ui";
import { NavBarItem } from "./NavBarItem";

export const NavBar = ({ selectedItem, handleNavBarChange, finishLoad }) => {
    const elements = [
        { text: "Home", icon: <HomeIcon /> },
        { text: "Projects", icon: <AccountTreeIcon /> },
        { text: "Skills", icon: <AssistantIcon /> },
        { text: "testimony", icon: <MilitaryTechIcon /> },
        { text: "Contact", icon: <PermContactCalendarIcon /> },
    ];

    const indicator = useRef();

    useEffect(() => {
        document.documentElement.style.setProperty("--position", selectedItem);
    }, [selectedItem]);

    const { darkTheme } = useSelector((state) => state.theme);
    const dispatch = useDispatch();

    const handleToggleTheme = () => {
        batch(() => {
            dispatch(toggleInvertFilter());
            dispatch(toggleTheme());
        });
    };

    const scrollToTop = (event) => {
        handleNavBarChange(event, 0);
    };

    // const triggerNavbar = useScrollTrigger({ disableHysteresis: true, threshold: 200, });

    const triggerScroll = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
    });

    const countLoadedItems = () => {
        return Object.values(finishLoad).reduce((total, num) => {
            return total + Math.round(num);
        }, 0);
    };

    return (
        <>
            <Fade in={true}>
                <StyledNav className="navbar-container">
                    <ul className="list">
                        <div
                            ref={indicator}
                            data-indicator
                            className="indicator"
                        >
                            <div className="corners"></div>
                        </div>
                        {elements.map((elm, index) => (
                            <NavBarItem
                                label={elm.text}
                                icon={elm.icon}
                                itemIndex={index}
                                selectedItem={selectedItem}
                                key={index}
                                handleClick={handleNavBarChange}
                                isLoaded={countLoadedItems() > index}
                            />
                        ))}
                    </ul>
                </StyledNav>
            </Fade>
            <ThemeFab size="small" label={"theme"} onClick={handleToggleTheme}>
                {darkTheme ? <LightModeIcon /> : <DarkModeIcon />}
            </ThemeFab>

            {triggerScroll && (
                <ScrollToTopFab
                    size="small"
                    label={"scroll to top"}
                    onClick={scrollToTop}
                >
                    <KeyboardArrowUpIcon />
                </ScrollToTopFab>
            )}
        </>
    );
};

const StyledNav = styled("nav")`
    && *,
    && *::before,
    *::after {
        box-sizing: border-box;
        font-family: Arial, Helvetica, sans-serif;
    }

    && {
        /* background-color: var(--secondary-color); */
        /* background-color: rgba(111, 111, 111, 0.4); */
        background-color: rgba(197, 197, 197, 0.4);
        backdrop-filter: blur(5px);
        border-radius: 0px;
        width: max-content;
        padding: 1px calc(var(--nav-item-padding) * 1.5);
        position: fixed;
        top: auto;
        transform: translate(-50%);
        box-shadow: 0px 0px 10px 1px #fff;
        z-index: 2;

        transform: rotate(90deg) translateY(0%);
        left: calc(100% - 220px);
        bottom: 50%;
        transition: all 0.5s ease;
    }

    && .list {
        display: flex;
        margin: 0;
        padding: 0;
        list-style: none;
        position: relative;
    }

    && .indicator {
        position: absolute;
        left: calc(
            var(--position) * (var(--icon-size) + var(--nav-item-padding) * 2)
        );
        top: 10%;
        transition: 250ms ease-in-out;
    }

    && .indicator::after,
    .indicator::before {
        content: "";
        position: absolute;
        border-radius: 100%;
    }

    && .indicator::after {
        background-color: var(--primary-color);
        width: calc(var(--icon-size) * 2);
        height: calc(var(--icon-size) * 2);
        top: calc(-0.5 * var(--icon-size));
        border-radius: 0%;
        /* box-shadow: 0px 0px 5px 1px var(--primary-color); */
        box-shadow: 0px 0px 5px 1px rgba(255, 255, 255, 0.8);
    }
    && .indicator::before {
        background-color: var(--primary-color);
        top: calc(-0.5 * var(--icon-size) - var(--indicator-spacing));
        left: calc(-1 * var(--indicator-spacing));
        border-radius: 0px;
        transform: scale(1);
    }

    @media (max-width: 600px), (min-width: 1920px) {
        && {
            transform: rotate(0deg) translateX(-50%);
            left: 50%;
            bottom: 15px;
            width: calc(100% - 0px);
            /* box-shadow: 0px -3px 3px 20px rgba(255, 255, 255, 0.8); */
            /* filter: drop-shadow(0px 0px 30px rgba(0,0,0,.5)); */
            /* background-color: rgba(255, 255, 255, 0.4); */
            display: flex;
            justify-content: center;
        }

        && .indicator {
            top: 0%;
        }
    }

    @media (min-width: 1920px) {
        && {
            width: 25%;
        }
    }

    @media screen and (max-height: 450px) {
        && {
            display: none;
            visibility: hidden;
        }
    }
`;

const ThemeFab = styled(StyledFab)`
    && {
        top: 6px;
        transition: 2s;
        /* background-color: rgba(111, 111, 111, 0.4); */
        background-color: rgba(197, 197, 197, 0.4);
        backdrop-filter: blur(5px);
        color: var(--secondary-color);
    }

    @media screen and (max-width: 600px) {
        && {
            transition: 2s;
        }
    }
    @media screen and (min-width: 1920px) {
        && {
            transition: 2s;
            top: auto;
            bottom: 15px;
            right: auto;
            left: 30%;
            /* box-shadow: 0px -3px 3px 20px rgba(255, 255, 255, 0.8);
            filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.5)); */
            /* background-color: rgba(255, 255, 255, 0.4); */
        }
    }
`;

const ScrollToTopFab = styled(StyledFab)`
    && {
        bottom: 6px;
        /* background-color: rgba(111, 111, 111, 0.4); */
        background-color: rgba(197, 197, 197, 0.4);
        backdrop-filter: blur(5px);
        color: var(--secondary-color);
    }

    @media screen and (max-width: 600px) {
        && {
            bottom: 80px;
        }
    }
    @media screen and (min-width: 1920px) {
        && {
            bottom: 15px;
            right: 30%;
            /* box-shadow: 0px -3px 3px 20px rgba(255, 255, 255, 0.8);
            filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.5)); */
            /* background-color: rgba(255, 255, 255, 0.4); */
        }
    }
`;
