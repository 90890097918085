import React, { useEffect, useLayoutEffect } from "react";
import styled from "styled-components";
import { Container } from "@mui/material";
import { useSelector } from "react-redux";
import { AnimatePresence } from "framer-motion";
// import { v4 as uuidv4 } from 'uuid'

import GlobalStyle from "@/styles/GlobalStyle";

import { Intro } from "@/components/intro";
import { NavBar } from "@/components/navBar";
import { Profile } from "@/components/profile";
import { Projects } from "@/components/projects";
import { Skills } from "@/components/skills";
import { Testimonials } from "@/components/testimonials";
import { Contacts } from "@/components/contacts";

import TitleBehaviour from "@/helpers/TitleBehaviour";
import { useHandleNavBar } from "@/hooks/useHandleNavBar";
import { CustomFooter } from "@/components/ui/CustomFooter";

console.log(TitleBehaviour.developerName());
TitleBehaviour.typeWriter();
TitleBehaviour.logVisitor();

const App = (props) => {
    const { darkTheme, filter, primaryColor, secondaryColor } = useSelector(
        (state) => state.theme
    );
    const { finishLoad } = useSelector((state) => state.loading);

    const { refs, inViews, handleNavBarChange, selectedItem } =
        useHandleNavBar();

    useLayoutEffect(() => {
        window.history.scrollRestoration = "manual";
        //window.scrollTo(0, 0)
    }, []);

    useEffect(() => {
        document.documentElement.style.filter = filter;
    }, [darkTheme]);

    useEffect(() => {
        document.documentElement.style.setProperty(
            "--rgb-primary-color",
            primaryColor
        );
    }, [primaryColor]);

    useEffect(() => {
        document.documentElement.style.setProperty(
            "--rgb-secondary-color",
            secondaryColor
        );
    }, [secondaryColor]);

    return (
        <AppContainer>
            <GlobalStyle />

            <AnimatePresence onExitComplete={(e) => {}}>
                {!finishLoad.intro && <Intro />}
            </AnimatePresence>
            {finishLoad.intro && (
                <>
                    <NavBar
                        selectedItem={selectedItem}
                        handleNavBarChange={handleNavBarChange}
                        finishLoad={finishLoad}
                    />

                    {finishLoad.intro && (
                        <Profile scrollTo={refs[0]} inView={inViews[0]} />
                    )}

                    {finishLoad.profile && (
                        <Projects scrollTo={refs[1]} inView={inViews[1]} />
                    )}

                    {finishLoad.projects && (
                        <Skills scrollTo={refs[2]} inView={inViews[2]} />
                    )}

                    {finishLoad.skills && (
                        <Testimonials scrollTo={refs[3]} inView={inViews[3]} />
                    )}

                    {finishLoad.testimonials && (
                        <Contacts scrollTo={refs[4]} inView={inViews[4]} />
                    )}

                    <CustomFooter />
                </>
            )}
        </AppContainer>
    );
};

export default App;

const AppContainer = styled(Container)`
    && {
        /* min-width: 100vw; */
        /* background: #ffffff;
    color: #000000; */
    }
    @media (min-width: 600px) {
        && {
            padding-left: 50px;
            padding-right: 50px;
        }
        @media screen and (min-width: 768px) {
            && {
                padding-left: 80px;
                padding-right: 80px;
            }
        }
    }
`;

// filter: ${filter};
