import React, { useLayoutEffect } from 'react'
import styled from 'styled-components'
import { Typography } from '@mui/material'
import { motion, useAnimation } from 'framer-motion'

const textSquareVariants = {
    hidden: { opacity: 0 },
    visible: (i)=>{
        return {
            opacity: 1,
            transition: {
            duration: 1,
            delay: 3.6+i*0.6
            }
        }
    }
}


export const LoadingSquare = ({index, textContent}) => {

    const textControls = useAnimation()

    useLayoutEffect(()=>{
        textControls.start(i=> textSquareVariants.visible(i))
    }, [textContent])

    return (
        <StyledSquare className="loadingSquare" >
            <motion.div initial='hidden' animate={textControls} variants={ textSquareVariants } custom={index} >
                <Typography variant="h6" component="h2" >
                    {textContent}
                </Typography>
            </motion.div>
        </StyledSquare>
    )
}



const StyledSquare = styled(motion.div)`
    && {
        width:50px;
        height:50px;
        border-radius:0%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        /* box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0); */
    }
    & .MuiTypography-root {
        font-weight: 400;
        color: #fff;
        animation-duration: 2s;
        font-family:  var(--portfolio-font);
        letter-spacing: 4px;
        padding: 10px 1px;
        text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15);
    }
`