import React from "react";
import styled from "styled-components";
import { Tooltip } from "@mui/material";

export const NavBarItem = ({
    label,
    icon,
    itemIndex,
    selectedItem,
    handleClick,
    isLoaded,
}) => {
    return (
        <StyledItem
            // style={{pointerEvents: isLoaded?'auto':'none' }}
            style={{ visibility: isLoaded ? "visible" : "hidden" }}
        >
            <Tooltip /*title={label}*/>
                <div
                    className={
                        selectedItem === itemIndex ? "item active" : "item"
                    }
                    onClick={(e) => {
                        handleClick(e, itemIndex);
                    }}
                >
                    <p className="icon">{icon}</p>
                    <p className="text">{label}</p>
                </div>
            </Tooltip>
        </StyledItem>
    );
};

const StyledItem = styled("li")`
    && .item {
        color: var(--secondary-color);
        text-decoration: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: calc(var(--nav-item-padding) * 0.2)
            calc(var(--nav-item-padding) * 1);
        cursor: pointer;
    }
    && .text {
        font-size: 0.8em;
        opacity: 0;
        pointer-events: none;
        transition: 250ms ease-in-out;
        position: absolute;
        bottom: calc(0.5 * var(--nav-item-padding));
        transform: translateY(100%);
    }

    && .icon {
        position: relative;
        transition: 250ms ease-in-out;
        transform: rotate(-90deg);
    }

    && .icon svg {
        fill: currentColor;
        width: var(--icon-size);
        height: var(--icon-size);
        display: block;
    }

    && .active {
        color: #fff;
    }

    && .active .text {
        pointer-events: all;
        opacity: 0;
        visibility: hidden;
    }

    && .active .icon {
        transform: translateY(calc(50% - var(--nav-item-padding)))
            rotate(-90deg);
    }

    @media (max-width: 600px), (min-width: 1920px) {
        && .icon {
            transform: rotate(0deg);
        }
        && .active .icon {
            transform: translateY(calc(-0% - var(--nav-item-padding)))
                rotate(0deg);
        }
        && .active .text {
            opacity: 1;
            visibility: visible;
        }
    }
`;
