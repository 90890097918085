import React, { useRef, useState } from "react";
import styled from "styled-components";
import {
    Alert,
    Box,
    Snackbar,
    TextField,
    Slide,
    InputAdornment,
    Chip,
    Divider,
    Grid,
    Button,
} from "@mui/material";

import SendIcon from "@mui/icons-material/Send";
import AccountBox from "@mui/icons-material/AccountBox";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
// import EmailIcon from '@mui/icons-material/Email'
import MessageIcon from "@mui/icons-material/Message";
import LoadingButton from "@mui/lab/LoadingButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { motion } from "framer-motion";
import emailjs from "@emailjs/browser";

import { useDispatch } from "react-redux";
import { setFinishComponentLoad } from "@/context/redux.loading";

import apiKey from "@/data/EmailKey";
import { CustomDivider } from "@/components/ui";
import { ContactsAccountsIcons } from "./ContactsAccountsIcons";

import profilePhoto from "@/assets/logo/profile-photo_02.png";
// import TitleBehaviour from '@/services/TitleBehaviour'

const contactsVariants = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.5,
            when: "beforeChildren",
            // delayChildren: 1,
            // staggerChildren: 0.5
        },
    },
    hover: {},
};

const formVariants = {
    hidden: { opacity: 0, x: "-300px" },
    visible: (i) => {
        return {
            opacity: 1,
            x: 0,
            transition: {
                // duration: 1,
                type: "spring",
                bounce: 0.3,
                delay: 1 + 0.5 * i,
            },
        };
    },
    hover: {},
};

const snackbarTransition = React.forwardRef(function Transition(props, ref) {
    return <Slide {...props} direction="down" />;
});

export const Contacts = (props) => {
    const dispatch = useDispatch();
    const handleAnimationComplete = (definition) => {
        dispatch(setFinishComponentLoad("contacts"));
    };

    const refName = useRef();
    const refEmail = useRef();
    const refMessage = useRef();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [isSending, setIsSending] = useState(false);

    const [focusedName, setFocusedName] = useState(false);
    const [focusedemail, setFocusedEmail] = useState(false);
    const [focusedmessage, setFocusedMessage] = useState(false);
    const validateName = () =>
        (focusedName && name && name.length > 1) || !focusedName;
    const validateEmail = () => {
        // let rExp = /\S+@\S+\.\S+/
        let rExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        return (focusedemail && email && rExp.test(email)) || !focusedemail;
    };
    const validateMessage = () =>
        (focusedmessage && message && message.length > 1) || !focusedmessage;

    const sendEmail = (e) => {
        e.preventDefault();

        if (!focusedName || !validateName()) {
            setSnackbarState({
                open: true,
                type: "error",
                message: `invalid Name!`,
            });
            refName.current.firstChild.focus();
            return;
        }
        if (!focusedemail || !validateEmail()) {
            setSnackbarState({
                open: true,
                type: "error",
                message: `invalid Email address!`,
            });
            refEmail.current.firstChild.focus();
            return;
        }
        if (!focusedmessage || !validateMessage()) {
            setSnackbarState({
                open: true,
                type: "error",
                message: `invalid Message!`,
            });
            refMessage.current.firstChild.focus();
            return;
        }

        setIsSending(true);
        emailjs
            .send(
                apiKey.SERVICE_ID,
                apiKey.TEMPLATE_ID,
                {
                    from_name: name,
                    from_email: email,
                    message: message,
                },
                apiKey.PUBLIC_KEY
            )
            .then(
                (result) => {
                    setIsSending(false);
                    setName("");
                    setEmail("");
                    setMessage("");
                    setFocusedName(false);
                    setFocusedEmail(false);
                    setFocusedMessage(false);
                    setSnackbarState({
                        open: true,
                        type: "success",
                        message: `Message Sent successfully!`,
                    }); // result.text
                },
                (error) => {
                    setIsSending(false);
                    setSnackbarState({
                        open: true,
                        type: "error",
                        message: `An error occurred, Please try again.`,
                    }); // ${error.text}
                }
            );
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        setSnackbarState({
            open: true,
            type: "success",
            message: `Email address copied!`,
        });
    };

    const [snackbarState, setSnackbarState] = useState({
        open: false,
        type: null,
        message: null,
    });

    const handleClose = (event, reason) => {
        if (reason === "clickaway") return;
        setSnackbarState((prevState) => ({ ...prevState, open: false }));
    };

    return (
        <ContactsContainer
            ref={props.scrollTo}
            // animate={controls}
            initial={"hidden"}
            variants={contactsVariants}
            whileInView={"visible"}
            viewport={{ once: true, amount: 0.2 }}
            onAnimationComplete={(definition) =>
                handleAnimationComplete(definition)
            }
        >
            <CustomDivider text="Contact" />

            <StyledForm>
                <Grid
                    container
                    style={{
                        width: "100%",
                        marginTop: "-80px",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <ContactsAccountsIcons />
                </Grid>

                <motion.div variants={formVariants} custom={1}>
                    <TextField
                        ref={refName}
                        type="text"
                        error={validateName() ? false : true}
                        label={validateName() ? "Name" : "Error"}
                        helperText={validateName() ? "" : "Incorrect entry."}
                        InputProps={{
                            autoComplete: "none",
                            endAdornment: (
                                <InputAdornment position="end">
                                    <AccountBox
                                        color={
                                            validateName() ? "inherit" : "error"
                                        }
                                    />
                                </InputAdornment>
                            ),
                        }}
                        placeholder="Name"
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value);
                            setFocusedName(true);
                        }}
                    />
                </motion.div>

                <motion.div variants={formVariants} custom={2}>
                    <TextField
                        ref={refEmail}
                        type="text"
                        error={validateEmail() ? false : true}
                        label={validateEmail() ? "Email" : "Error"}
                        helperText={validateEmail() ? "" : "Incorrect entry."}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <AlternateEmailIcon
                                        color={
                                            validateEmail()
                                                ? "inherit"
                                                : "error"
                                        }
                                    />
                                </InputAdornment>
                            ),
                        }}
                        placeholder="Email"
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setFocusedEmail(true);
                        }}
                    />
                </motion.div>

                <motion.div variants={formVariants} custom={2}>
                    <TextField
                        ref={refMessage}
                        type="text"
                        error={validateMessage() ? false : true}
                        label={validateMessage() ? "Message" : "Error"}
                        helperText={validateMessage() ? "" : "Incorrect entry."}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <MessageIcon
                                        color={
                                            validateMessage()
                                                ? "inherit"
                                                : "error"
                                        }
                                    />
                                </InputAdornment>
                            ),
                        }}
                        multiline
                        rows={4}
                        placeholder="Message"
                        value={message}
                        onChange={(e) => {
                            setMessage(e.target.value);
                            setFocusedMessage(true);
                        }}
                    />
                </motion.div>

                <motion.div variants={formVariants} custom={3}>
                    <SubmitContainer>
                        <SubmitButton
                            loading={isSending}
                            // loadingIndicator="..."
                            loadingPosition="end"
                            onClick={sendEmail}
                            variant="contained"
                            size="large"
                            endIcon={<SendIcon />}
                        >
                            Send
                        </SubmitButton>
                    </SubmitContainer>
                </motion.div>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <motion.div variants={formVariants} custom={4}>
                            <Divider
                                textAlign="center"
                                orientation="horizontal"
                                sx={{ margin: "50px 20% 50px 20%" }}
                            >
                                <Chip label="Or" sx={{ borderRadius: 0 }} />
                            </Divider>
                        </motion.div>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sx={{ display: "flex", justifyContent: "center" }}
                    >
                        <motion.div variants={formVariants} custom={5}>
                            <EmailTextCopy
                                onClick={(e) =>
                                    copyToClipboard(
                                        "cntcts.dev.loker@outlook.com"
                                    )
                                }
                            >
                                <CopyButton
                                    variant="outlined"
                                    size="large"
                                    endIcon={<ContentCopyIcon />}
                                >
                                    cntcts.dev.loker@outlook.com
                                </CopyButton>
                            </EmailTextCopy>
                        </motion.div>
                    </Grid>
                </Grid>
            </StyledForm>

            {/* <pre>{TitleBehaviour.developerName()}</pre> */}

            <Snackbar
                open={snackbarState.open}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                onClose={handleClose}
                TransitionComponent={snackbarTransition}
            >
                <Alert
                    onClose={handleClose}
                    variant="filled"
                    severity={snackbarState.type}
                    sx={{ width: "100%" }}
                >
                    {snackbarState.message}
                </Alert>
            </Snackbar>
        </ContactsContainer>
    );
};

const ContactsContainer = styled(motion.div)`
    && {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
`;

const StyledForm = styled(Box)`
    && {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 20px 0px;

        position: relative;
        /* background:var(--secondary-color); */
        /* background: linear-gradient(180deg,#1cb5e022 0%,#00085122 100%); */
        background-color: #ffffff88;

        position: relative;
        border-radius: 0px;
        /* box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0); */
        box-shadow: 10px 10px 50px 2px #c0c0c085;
        backdrop-filter: blur(2px);
        border: 1px #fff solid;
        /* border-top: 80px rgba(var(--rgb-primary-color), 0.99) solid; */
        border-top: 70px rgba(197, 197, 197, 0.5) solid;
        padding-bottom: 2rem;

        background-image: none;
        /* box-shadow: -4px -4px 0px 0px rgba(var(--rgb-primary-color), 0.4),
            -7px -7px 0px 0px rgba(var(--rgb-primary-color), 0.17),
            0px 10px 13px -7px rgba(var(--rgb-primary-color), 1); */
        box-shadow: -4px -4px 0px 0px rgba(197, 197, 197, 0.4),
            -7px -7px 0px 0px rgba(197, 197, 197, 0.17),
            0px 10px 13px -7px rgba(197, 197, 197, 1);
    }

    /* &&:hover{
        box-shadow: -4px -4px 0px 0px rgba(var(--rgb-secondary-color), 0.4),
                    -7px -7px 0px 0px rgba(var(--rgb-secondary-color), 0.17),
                    0px 10px 13px -7px rgba(var(--rgb-secondary-color), 1);
    } */
    /* &&::before{
        content: '';
        width: 0;
        height: 0;
        border-bottom: 60px solid #9090905C;
        border-left: 30px solid transparent;
        position: absolute;
        top: 0;
        left: -30px;
    } */
    && > div {
        width: 80%;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
    && .MuiOutlinedInput-root {
        border-radius: 0;
        border-color: var(--primary-color);
    }
    && .MuiTextField-root {
        width: 100%;
    }
    && .MuiInputAdornment-root {
        /* color: var(--secondary-color); */
    }

    & label.Mui-focused {
        color: var(--primary-color);
    }
    & .MuiInput-underline:after {
        border-bottom-color: var(--primary-color);
    }
    & .MuiOutlinedInput-root {
        & fieldset {
            border-color: #ccc;
        }
        &:hover fieldset {
            border-color: #ccc;
        }
        &.Mui-focused fieldset {
            border-color: var(--primary-color);
        }
        &.Mui-focused .MuiInputAdornment-root {
            color: var(--primary-color);
        }
    }

    @media screen and (max-width: 768px) {
        && > div {
            width: 90%;
        }
    }

    @media screen and (min-width: 1200px) {
        && {
            background-image: url(${profilePhoto});
            /* background-size: contain;
            background-repeat: no-repeat;
            background-position: right;
            background-blend-mode: luminosity;
            background-origin: content-box; */
            background-image: none;
        }
    }
`;

const SubmitContainer = styled(Box)`
    && {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        width: 100%;
    }
    @media screen and (max-width: 600px) {
        && {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
`;

const CopyButton = styled(Button)`
    && {
        background: var(--secondary-color);
        color: #fff;
        margin-top: 10px;
        text-transform: lowercase;
    }
    &&.MuiButton-root:hover {
        background: var(--primary-color);
    }
`;

const SubmitButton = styled(LoadingButton)`
    && {
        background: var(--primary-color);
        /* padding: 10px; */
        /* width: 30%; */
        margin-top: 10px;
    }
    &&.MuiLoadingButton-root:hover {
        background: var(--secondary-color);
    }
`;

const EmailTextCopy = styled(Box)`
    && {
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* background: var(--secondary-color); */
        /* background: #adadad; */
        /* color: #ffffff; */
        padding: 5px;
        cursor: pointer;
    }
    && .MuiChip-root {
        border-radius: 5px;
    }
    && .MuiChip-label {
        font-family: var(--portfolio-font);
        text-transform: lowercase;
        color: #838181;
    }
`;
