import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { motion, useAnimation } from 'framer-motion'

import { setFinishComponentLoad } from '@/context/redux.loading'
import { LoadingSquare } from './LoadingSquare'
import { DeveloperName } from '@/components/ui/DeveloperName'


const introVariants = {
  hidden: { opacity: 0},
  visible: {
      opacity: 1,
      transition: {
        duration: 0.2,
        when: "beforeChildren",
        delayChildren: 1,
        // staggerChildren: 1.5
      }
  }
}

const squareTransition = (duration, delay)=>{
  return {
    when: "beforeChildren",
    delayChildren: 1,
    // staggerChildren: 1.5,
    duration: duration,
    type: 'spring',
    bounce: 0.3,
    delay: delay? delay: 0,
  }

}


const squareContainerVariants = {
  hidden: { opacity: 0 },
  animationOne: {
    opacity: 1,
    transition: squareTransition(1)
  },
  animationTow: {
    width: 'auto', height: 'auto',
    scale: 0.99,
    transition: squareTransition(1, 0)
  },
  animationThree: {
    rotateY: 360*10,
    scale: 1,
    transition: { duration: 1.5, type: 'spring' }
  },
  animationFour: {
    scaleX: 3, scaleY: 2,
    transition: {...squareTransition(0.4), repeatType: 'mirror', repeat: 5} // yoyo: 3 } repeatType: 'reverse',
  },
  animationFive: {
    rotateX: 90,
    transition: { duration: 0.5, type: 'tween' }
  }
  ,
  animationSix: {
    opacity: 0,
    transition: squareTransition(0.5)
  }
}

const leftSquareVariants = {
  hidden: { x: 0 },
  animationOne: {
    x: 'calc(50vw - 25px)',
    transition: squareTransition(2)
  },
  animationTow: {
    x: 0,
    transition: squareTransition(1)
  }
}

const rightSquareVariants = {
  hidden: { x: 0 },
  animationOne: { 
    x: 'calc(-50vw + 25px)',
    transition: squareTransition(2)
  },
  animationTow: {
    x: 0,
    transition: squareTransition(1)
  }
}

const developerNameVariants = {
  hidden: { rotateX: 90 },
  animationOne: {
    rotateX: 0,
    transition: {
      duration: 1,
      delay: 6.0
    }
  },
  animationTow: {
      //rotateZ: [0, 0, -15, 10, -10, 6, -4, 0, 0],
      skewY: ['-20deg', '20deg', '-15deg', '15deg', '-10deg', '10deg', '-5deg', '5deg', '0deg'],
      rotateX: [45, -45, 30, -30, 20, -20, 15, -15, 10, -10, 8, -8, 4, -4, 2, -2, 1, -1, 0],
      transition :{
        duration: 1.5,
        times: [0, 0.07, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 1],
        type: 'spring',
      }
  }
}




export const Intro = (props) => {

    const dispatch = useDispatch()
    const handleAnimationComplete = (definition)=>{
      definition==='animationTow' && dispatch(setFinishComponentLoad('intro'))
    }


    const containerControls = useAnimation()
    const leftControls = useAnimation()
    const rightControls = useAnimation()
    const nameControls = useAnimation()


    const sequentialSquareAnimation = async(controls)=>{
      await controls.start('animationOne')
      await controls.start('animationTow')
    }
    const sequentialSquareContainerAnimation = async(controls)=>{
      await controls.start('animationOne')
      await controls.start('animationTow')
      await controls.start('animationThree')
      await controls.start('animationFour')
      await controls.start('animationFive')
      await controls.start('animationSix')
    }

    useEffect(()=>{
        sequentialSquareAnimation(leftControls)
        sequentialSquareAnimation(rightControls)
        sequentialSquareContainerAnimation(containerControls)
        sequentialSquareAnimation(nameControls)
    },[leftControls, rightControls, containerControls, nameControls])

    
    return (
        <StyledContainer initial='hidden' animate='visible' variants={ introVariants } exit={{ y: '-100vh', opacity: 0, transition: { duration: 0.4 } }} >
            
            <StyledSquaresContainer initial='hidden' animate={containerControls} variants={ squareContainerVariants } >
                <LoadingSquare initial='hidden' animate={leftControls} variants={ leftSquareVariants } textContent='D' index={0} />
                <LoadingSquare initial='hidden' animate={rightControls} variants={ rightSquareVariants } textContent='L' index={1} />
            </StyledSquaresContainer>

            <StyledDeveloperNameContainer 
                initial='hidden' animate={nameControls} variants={ developerNameVariants } 
                onAnimationComplete={ definition => handleAnimationComplete(definition) } 
            >
                <DeveloperName  />
            </StyledDeveloperNameContainer>
            
        </StyledContainer>
    )
}


const StyledContainer = styled(motion.div)`
  & {
      opacity: 1;
      width: 100vw;
      height:100vh;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(0deg, var(--primary-color) 0%, var(--secondary-color) 100%);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
  }
`

const StyledSquaresContainer = styled(motion.div)`
    && {
        width:100%;
        height:60px;
        background:rgba(200, 200, 200, 0.2);
        border-radius: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
    }
    && .loadingSquare:nth-child(2){
        background:var(--secondary-color);
        border: 0px var(--primary-color) solid;
    }
    && .loadingSquare:nth-child(1){
        background:var(--primary-color);
        border: 0px var(--secondary-color) solid;
    }
`


const StyledDeveloperNameContainer = styled(motion.div)`
    && {
        position: absolute;
    }
`