import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './App'
import reportWebVitals from './reportWebVitals'

import { Provider } from 'react-redux'
// import { portfolioStore } from '@context/redux.store'
import { portfolioStore } from '@/context/redux.store'


const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={portfolioStore}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>
);

reportWebVitals()
