export default {
    hexToRGB : (hex) => {
        hex = hex.replace('#', '')
        return   hex.match(new RegExp('(.{' + hex.length/3 + '})', 'g'))
                    .map(l=> parseInt(hex.length%2 ? l+l : l, 16) )
                    .join(',')
    },
    hexToRGBA : (hex, opacity) => {
        return 'rgba(' + (hex = hex.replace('#', '')).match(new RegExp('(.{' + hex.length/3 + '})', 'g')).map(function(l) { return parseInt(hex.length%2 ? l+l : l, 16) }).concat(isFinite(opacity) ? opacity : 1).join(',') + ')';
    }
}