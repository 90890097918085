import React , { useEffect } from 'react'
import styled from 'styled-components'
import { Box, Container, Dialog, DialogContent, DialogTitle, Grid, IconButton, Slide, SvgIcon, Typography } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { motion } from 'framer-motion'
import { useSelector } from 'react-redux'
import { QRCode } from 'react-qrcode-logo'
import ReactDOMServer from 'react-dom/server'

import { PhotoSlider } from './PhotoSlider'
import { CustomIcon } from '@/components/ui'

import { getSkill } from '@/data/SkillsList'
import { SkillItem } from '@/components/skills'

const wiggleVariants = {
    wiggle: {
        rotateZ: [0, 0, -15, 10, -10, 6, -4, 0, 0],
        transition :{
            duration: 1,
            times: [0, 0.07, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 1],
            type: 'tween'
        }
    }
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})


export const ProjectDetailDialog = ({project, openedDialog, onDialogClose}) => {

    const { darkTheme, primaryColor, secondaryColor  } = useSelector(state=> state.theme)


    useEffect(()=>{
        if(openedDialog){
            // controls.start('visible')
            // initial={openedDialog?'visible':'hidden'} animate={controls} variants={dialogVariants}
        }
    }, [openedDialog])
    

    const toUrl = (element) => {
        if(typeof element === 'object'){
            let html = ReactDOMServer.renderToString(<SvgIcon component={element} inheritViewBox />)
                                    .replace(`<svg `, `<svg xmlns="http://www.w3.org/2000/svg" `)
                                    .replace(/<path /g, `<path style="fill: rgb(${darkTheme?secondaryColor:primaryColor});" `)
            var b64 = 'data:image/svg+xml;base64,'+window.btoa(html)
            return b64
        }else{
            return element
        }
    }


    return (

        <>
            {
            project!=null &&
            
            <Dialog
                open={openedDialog}
                // onClose={onDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullScreen={true}
                scroll='paper'
                // fullWidth={true}
                maxWidth='xl'
                sx={{width: '100vw'}}
                TransitionComponent={Transition}
                //keepMounted
                //aria-describedby="alert-dialog-slide-description"
                //transitionDuration={1}
            >
                <CustomDialogTitle id="alert-dialog-title" >
                    <Container >
                        <Grid>
                            <Box>
                                <IconButton onClick={onDialogClose} className='back' >
                                    <ArrowBackIosIcon /> 
                                </IconButton>
                            </Box>
                            <Box sx={{ width: '100%' }} >
                                <Typography variant="h6" component="h6" sx={{ filter: darkTheme? 'invert(1)':'invert(0)' }} >
                                    {project.title}
                                </Typography>
                            </Box>
                            <Box>
                                {
                                    project.links.map((link, index)=>(
                                        <motion.div variants={wiggleVariants} whileHover='wiggle' key={index} >
                                            <CustomIcon label={link.text} icon={link.icon} link={link.url} target={'_blank'} action={null} index={index} key={index} />
                                        </motion.div>
                                    ))
                                }    
                            </Box>
                        </Grid>
                    </Container>

                </CustomDialogTitle>

                <DetailContainer>

                    <Container>
                        <ProjectDescription container theme={darkTheme} >
                            <QRContainer item xs={12} sm={12} md={12} lg={12} xl={12}  >
                                <motion.div variants={wiggleVariants} whileHover='wiggle'  >
                                    {project.links[0] && (
                                    <a href={project.links[0].url} target={'_blank'} rel="noreferrer"  style={{ filter: darkTheme? 'invert(1)':'invert(0)' }}>
                                        <QRCode 
                                            value={project.links[0].url} 
                                            fgColor={`rgb(${secondaryColor})`}
                                            // logoWidth={150}
                                            // logoOpacity={0.2}
                                            logoImage={toUrl(project.logo)}
                                            removeQrCodeBehindLogo={true} 
                                            qrStyle={'dots'} 
                                            eyeRadius={5} 
                                            // theme={darkTheme}
                                            
                                        />
                                    </a>
                                    )}
                                </motion.div>
                            </QRContainer>
                            
                            <FullDescriptionContainer>
                                {/* <StyledDivider text='Description' style={{margin: '20px 0px'}} /> */}
                                <Typography  variant="h6" component="h6">
                                    <motion.div variants={wiggleVariants} whileHover='wiggle_' >
                                        {(
                                            <ul className='descriptionList' >
                                                {
                                                    project.fullDescription.map((item, i)=>
                                                        (<li key={i} >{item}</li>)
                                                    )
                                                }
                                            </ul>
                                        )}
                                    </motion.div>
                                </Typography>

                                <SkillsContainer container >
                                    {project.usedSkills.length>0 && (
                                        <Typography  variant="h6" component="h6">
                                            Developed using : 
                                        </Typography>
                                    )}
                                    <Grid>
                                    {
                                        project.usedSkills.map((label, i)=>{
                                            let skill = getSkill(label)
                                            return (
                                                <SkillItem label={skill.label} icon={skill.icon} key={i} />
                                            )
                                        })
                                    }
                                    </Grid>
                                </SkillsContainer>

                            </FullDescriptionContainer>

                            
                        </ProjectDescription>            

                    </Container>

                    <ScreenshotsContainer>
                        <PhotoSlider photosList={[project.image, ...project.screenshotList]} />
                    </ScreenshotsContainer>


                </DetailContainer>
                
            </Dialog>
            }
        </>
    )
}



const CustomDialogTitle = styled(DialogTitle)`
    && {
        padding: 0;
        z-index: 1;
        /* border-bottom: 4px solid var(--primary-color); */
        /* box-shadow: 0px 0px 20px 2px #ddd; */
    }
    && .MuiContainer-root {
        width: 100%;
        background-color: rgba(var(--rgb-secondary-color), 0.99);
        box-shadow: 0px 0px 20px 2px rgba(var(--rgb-secondary-color), 0.5);
        padding: 0px;
        box-shadow: 0px 0px 20px 2px #ddd;
    }
    && .MuiGrid-root {
        width: 100%;
        min-height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
    }
    && .MuiBox-root{
        width: 100%;
        display: flex;
        align-items: center;
    }
    && .MuiBox-root:first-child{
        justify-content: flex-start;
        margin-left: 10px;
    }
    && .MuiBox-root:nth-child(2){
        justify-content: center;
    }
    && .MuiBox-root:last-child{
        justify-content: flex-end;
        margin-right: 10px;
    }
    && .back {
        transition: 0.4s;
        color: #fff;
        border-radius: 0px;
        transform: scale(0.8);
        transition: 0.4s;
        /* margin-left: 10px; */
    }
    && .back:hover {
        background: var(--primary-color);
        /* transform: scale(0.9); */
    }
    && .MuiTypography-root {
        font-weight: bolder;
        color: #fff;
        /* font-family: fantasy; */
        font-family: var(--portfolio-font);
        letter-spacing: 4px;
        /* text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15); */
    }

    @media screen and (max-width: 600px) {
        && .MuiBox-root:first-child {
            width: 70px;
        }
    }
    && .MuiButtonBase-root.MuiIconButton-root{
        background: rgba(var(--rgb-primary-color), 0.49);
        /* box-shadow: -4px -4px 0px 0px rgba(var(--rgb-primary-color), 0.4),
                    -7px -7px 0px 0px rgba(var(--rgb-primary-color), 0.17),
                    0px 10px 13px -7px rgba(var(--rgb-primary-color), 1); */
    }
    && .MuiButtonBase-root.MuiIconButton-root:hover{
        background: rgba(var(--rgb-primary-color), 0.99);
    }
`

const DetailContainer = styled(DialogContent)`
    && {
        padding: 0;
        padding-top: 60px;
    }
    && .MuiContainer-root {
        padding: 0px 10px;
    }
    @media screen and (max-width : 768px){
        && .MuiContainer-root {
            padding: 0px 15px;
        }
    }
`

const ProjectDescription = styled(Grid)`
    && {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        margin-top: 7rem;
        margin-bottom: 7rem;
    }
    && .MuiBox-root {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        /* margin-top: 20px; */
        margin-left: 10px;
    }
    /* && .MuiGrid-root:nth-child(1){
        margin-top: -75px;
        padding-top: 150px;
    } */


    && #react-qrcode-logo {
        position: static;
        transition: 0.8s;
        background: var(--secondary-color);
        box-shadow: 0px 10px 13px -7px rgba(var(--rgb-secondary-color), 1);
        /* filter: ${(props) => props.theme?'invert(1) drop-shadow(2px 4px 6px #000)':'invert(0)'}; */
        cursor: pointer;
        box-shadow: -4px -4px 0px 0px rgba(var(--rgb-secondary-color), 0.4),
                    -7px -7px 0px 0px rgba(var(--rgb-secondary-color), 0.17),
                    0px 10px 13px -7px rgba(var(--rgb-secondary-color), 1);
    }

    @media screen and (max-width : 900px){
        && {
            /* flex-direction: column-reverse;
            flex-wrap: nowrap;
            margin-top: 60px; */
        }
        && .MuiGrid-root:nth-child(1), && .MuiGrid-root:nth-child(2){
            margin-bottom: 20px;
            justify-content: center;
        }
    }
`

const QRContainer = styled(Grid)`
    && {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        z-index: 1;
    }
`

const FullDescriptionContainer = styled(Box)`
    && .MuiTypography-root {
        text-align: justify;
        text-justify: inter-word;
    }
    && {
        text-indent: 0em;
        text-align: justify;
        text-justify: inter-word;
        position: relative;
        border-radius: 0px;
        
        background:var(--secondary-color);
        box-shadow: -4px -4px 0px 0px rgba(var(--rgb-secondary-color), 0.4),
                    -7px -7px 0px 0px rgba(var(--rgb-secondary-color), 0.17),
                    0px 10px 13px -7px rgba(var(--rgb-secondary-color), 1);
        color: #fff;
        padding: 1.5rem;
        min-height: 110px;
        transition: 0.4s;
        margin-top: -75px;
        padding-top: 120px;
    }
    &&:hover {
        color: #fff;
        background:var(--primary-color);
        box-shadow: -4px -4px 0px 0px rgba(var(--rgb-primary-color), 0.4),
                    -7px -7px 0px 0px rgba(var(--rgb-primary-color), 0.17),
                    0px 10px 13px -7px rgba(var(--rgb-primary-color), 1);
    }
    && .descriptionList {
        list-style-type: square;
        margin-left: -20px;
    }
    /* && .descriptionList li::before {
        content: '';
        background: var(--secondary-color);
        display: inline-block;
        width: 0.6rem;
        height: 0.6rem;
        margin-right: 2rem;
    } */
`

const SkillsContainer = styled(Grid)`
    && {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        /* background: rgba(var(--rgb-primary-color), 0.4); */
        padding: 1em;
    }
    && .MuiTypography-root {
        margin-top: 3rem;
        margin-bottom: 1rem;
        /* color: var(--secondary-color); */
    }
    && .MuiGrid-root{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
    }
    && .MuiChip-root{
    }
    && .MuiAvatar-root {
        border-radius: 0px;
    }
`

const ScreenshotsContainer = styled(Container)`
    && {
        padding: 0px 10px;
        padding-top: 60px;
        margin-top: -00px;
        background: var(--rgb-primary-color);
        /* box-shadow: -4px -4px 0px 0px rgba(var(--rgb-primary-color), 0.4),
                    -7px -7px 0px 0px rgba(var(--rgb-primary-color), 0.17),
                    0px 10px 13px -7px rgba(var(--rgb-primary-color), 1); */
    }
    &&.MuiContainer-root {
        padding: 0px 0px;
    }
    && .swiper-slide {
        /* border: 15px solid rgba(var(--rgb-secondary-color), 0.99); */
        border-top-width: 15px;
    }
    && .swiper-button-prev, && .swiper-button-next{
        /* box-shadow: -4px -4px 0px 0px rgba(var(--rgb-primary-color), 0.4),
                    -7px -7px 0px 0px rgba(var(--rgb-primary-color), 0.17),
                    0px 10px 13px -7px rgba(var(--rgb-primary-color), 1); */
    }
`

