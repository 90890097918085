import React from "react";

import { ReactComponent as HTML5Icon } from "@/assets/black-svg/html5.svg";
import { ReactComponent as CSS3Icon } from "@/assets/black-svg/css3.svg";
import { ReactComponent as BootstrapIcon } from "@/assets/black-svg/bootstrap.svg";
import { ReactComponent as MaterialUIIcon } from "@/assets/black-svg/material-ui.svg";
import { ReactComponent as AntDesignIcon } from "@/assets/black-svg/ant-design.svg";
import { ReactComponent as TailwindIcon } from "@/assets/black-svg/tailwind.svg";
import { ReactComponent as Framework7Icon } from "@/assets/black-svg/framework7.svg";
import { ReactComponent as JavaScriptIcon } from "@/assets/black-svg/javascript.svg";
import { ReactComponent as TypeScriptIcon } from "@/assets/black-svg/typescript.svg";
import { ReactComponent as JqueryIcon } from "@/assets/black-svg/jquery.svg";
import { ReactComponent as ChartjsIcon } from "@/assets/black-svg/chartjs.svg";
import { ReactComponent as ReactIcon } from "@/assets/black-svg/react.svg";
import { ReactComponent as JavaIcon } from "@/assets/black-svg/java.svg";
import { ReactComponent as SpringBootIcon } from "@/assets/black-svg/springboot.svg";
import { ReactComponent as NodejsIcon } from "@/assets/black-svg/nodejs.svg";
import { ReactComponent as ExpressIcon } from "@/assets/black-svg/expressjs.svg";
import { ReactComponent as NestIcon } from "@/assets/black-svg/nestjs.svg";
import { ReactComponent as ElectronIcon } from "@/assets/black-svg/electron.svg";
import { ReactComponent as PythonIcon } from "@/assets/black-svg/python.svg";
import { ReactComponent as FlaskIcon } from "@/assets/black-svg/flask.svg";
import { ReactComponent as PHPIcon } from "@/assets/black-svg/php.svg";
import { ReactComponent as NETCoreIcon } from "@/assets/black-svg/dot-net-core.svg";
import { ReactComponent as MongoDBIcon } from "@/assets/black-svg/mongodb.svg";
import { ReactComponent as MySQLIcon } from "@/assets/black-svg/mysql.svg";
import {ReactComponent as PostgreSQLIcon} from "@/assets/black-svg/postgresql.svg"
import { ReactComponent as Db4oIcon } from "@/assets/black-svg/db4o.svg";
import { ReactComponent as DebianIcon } from "@/assets/black-svg/debian.svg";
import { ReactComponent as UbuntuIcon } from "@/assets/black-svg/ubuntu.svg"; // ubuntu
import { ReactComponent as KaliIcon } from "@/assets/black-svg/kali.svg";
// import {ReactComponent as PhotoShopIcon} from "@/assets/black-svg/photoshop.svg"
// import {ReactComponent as IlustratorIcon} from "@/assets/black-svg/illustrator.svg"
// import {ReactComponent as XDIcon} from "@/assets/black-svg/xd.svg"
// import {ReactComponent as DockerIcon} from "@/assets/black-svg/docker.svg"
// import {ReactComponent as VMwaireIcon} from "@/assets/black-svg/vmware.svg"
// import {ReactComponent as VirtualBoxIcon} from "@/assets/black-svg/virtualbox.svg"
import { ReactComponent as GitIcon } from "@/assets/black-svg/git.svg";
import { ReactComponent as JiraIcon } from "@/assets/black-svg/jira.svg";
import { ReactComponent as JestIcon } from "@/assets/black-svg/jest.svg";
import { ReactComponent as VitestIcon } from "@/assets/black-svg/vitest.svg";
import { ReactComponent as PlaywrightIcon } from "@/assets/black-svg/playwright.svg";
import { ReactComponent as AndroidIcon } from "@/assets/black-svg/android.svg";
import { ReactComponent as CordovaIcon } from "@/assets/black-svg/cordova.svg";
import { ReactComponent as ReactNativeIcon } from "@/assets/black-svg/react-native.svg";

const skillsList = {
    Frontend: [
        { label: "HTML5", icon: <HTML5Icon />, show: false },
        { label: "CSS3", icon: <CSS3Icon />, show: false },
        { label: "Bootstrap", icon: <BootstrapIcon /> },
        { label: "Material UI", icon: <MaterialUIIcon /> },
        { label: "Ant Design", icon: <AntDesignIcon /> },
        { label: "Tailwind", icon: <TailwindIcon />, show: false },
        { label: "Framework7", icon: <Framework7Icon />, show: false },
        { label: "JavaScript", icon: <JavaScriptIcon /> },
        { label: "TypeScript", icon: <TypeScriptIcon />, show: false },
        { label: "JQuery", icon: <JqueryIcon />, show: false  },
        { label: "Chartjs", icon: <ChartjsIcon /> },
        { label: "React", icon: <ReactIcon /> },
        // { label: "PhotoShop", icon: <PhotoShopIcon /> },
        // { label: "Ilustrator", icon: <IlustratorIcon /> },
        // { label: "XD", icon: <XDIcon /> },
    ],
    Backend: [
        { label: "NodeJs", icon: <NodejsIcon /> },
        { label: "Express", icon: <ExpressIcon /> },
        { label: "Nestjs", icon: <NestIcon /> },
        { label: "Electron", icon: <ElectronIcon /> },
        { label: "Python", icon: <PythonIcon /> },
        { label: "Flask", icon: <FlaskIcon /> },
        { label: "PHP", icon: <PHPIcon />, show: false },
        { label: ".NET Core", icon: <NETCoreIcon />, show: false },
        { label: "Java", icon: <JavaIcon />, show: false },
        { label: "SpringBoot", icon: <SpringBootIcon />, show: false },
    ],
    Databases: [
        { label: "mongoDB", icon: <MongoDBIcon /> },
        { label: "MySQL", icon: <MySQLIcon /> },
        { label: "PostgreSQL", icon: <PostgreSQLIcon /> },
        // { label: "db4o", icon: <Db4oIcon /> },
    ],
    // virtualization : [
    //     { label: "Docker", icon: <DockerIcon /> },
    //     { label: "VMware", icon: <VMwaireIcon /> },
    //     { label: "VirtualBox", icon: <VirtualBoxIcon /> },
    // ],
    Mobile: [
        { label: "SDK", icon: <AndroidIcon /> },
        { label: "React native", icon: <ReactNativeIcon /> },
        { label: "Cordova", icon: <CordovaIcon />, show: false },
    ],
    // OS: [
    //     { label: "Debian", icon: <DebianIcon /> },
    //     { label: "Ubuntu", icon: <UbuntuIcon /> },
    //     { label: "Kali", icon: <KaliIcon /> },
    // ],
    Others: [
        { label: "Git", icon: <GitIcon /> },
        { label: "Jira", icon: <JiraIcon /> },
        // { label: "Jest", icon: <JestIcon /> },
        { label: "Vitest", icon: <VitestIcon /> },
        { label: "PlayWright", icon: <PlaywrightIcon /> },
    ],
};
export default skillsList;

export const getSkill = (title) => {
    let skillItem = null;
    for (let category in skillsList) {
        for (let i = 0; i < skillsList[category].length; i++) {
            const skill = skillsList[category][i];
            if (skill.label === title) return skill;
        }
    }
    return skillItem;
};

// export default {
//     frontend : [
//         { label: "HTML5", icon: <HTML5Icon fill="#e44d26" />, bgColor: "#fff" },
//         { label: "CSS3", icon: <CSS3Icon fill="#1f62ae" />, bgColor: "#fff" },
//         { label: "Bootstrap", icon: <BootstrapIcon fill="#7b11f3" />, bgColor: "#fff" },
//         { label: "Material UI", icon: <MaterialUIIcon fill="#0081cb" />, bgColor: "#fff" },
//         { label: "Framework7", icon: <Framework7Icon fill="#ee350f" />, bgColor: "#fff" },
//         { label: "JavaScript", icon: <JavaScriptIcon fill="#000" />, bgColor: "#f0db4f" },
//         { label: "JQuery", icon: <JqueryIcon fill="#FFF" />, bgColor: "#0769ad" },
//         { label: "React", icon: <ReactIcon fill="#61dafb" />, bgColor: "#20232a" },
//         { label: "PhotoShop", icon: <PhotoShopIcon />, bgColor: "#363857" },
//         { label: "Ilustrator", icon: <IlustratorIcon />, bgColor: "#434c6a" },
//         { label: "XD", icon: <XDIcon />, bgColor: "#2e001f" },
//     ],
//     backend : [
//         { label: "Java", icon: <JavaIcon fill="#FFF" />, bgColor: "#e76f00" },
//         { label: "Python", icon: <PythonIcon />, bgColor: "#fff" },
//         { label: "PHP", icon: <PHPIcon fill="#000" />, bgColor: "#6181b6" },
//         { label: "NodeJs", icon: <NodejsIcon fill="#80bd01" />, bgColor: "#333333" },
//     ],
//     databases : [
//         { label: "mongoDB", icon: <MongoDBIcon fill="#499d4a" />, bgColor: "#fff" },
//         { label: "MySQL", icon: <MySQLIcon fill="#00678F" />, bgColor: "#fff" },
//         { label: "PostgreSQL", icon: <PostgreSQLIcon fill="#31648c" />, bgColor: "#fff" },
//     ],
//     virtualization : [
//         { label: "Docker", icon: <DockerIcon fill="#099CEC" />, bgColor: "#fff" },
//         { label: "VMware", icon: <VMwaireIcon fill="#099CEC" />, bgColor: "#fff" },
//         { label: "VirtualBox", icon: <VirtualBoxIcon  />, bgColor: "#fff" },
//     ],
//     OS : [
//         { label: "Debian", icon: <DebianIcon fill="#d0074e" />, bgColor: "#fff" },
//         { label: "Ubuntu", icon: <UbuntuIcon fill="#e14715" />, bgColor: "#fff" }, // #DD4814
//         { label: "Kali", icon: <KaliIcon fill="#e14715" />, bgColor: "#333" },
//     ]
// }
