import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Timeline } from "@mui/lab";
import { motion } from "framer-motion";

import { useDispatch } from "react-redux";
import { setFinishComponentLoad } from "@/context/redux.loading";

import ProjectsList from "@/data/ProjectsList";
import { ProjectModel } from "./ProjectModel";
import { ProjectDetailDialog } from "./ProjectDetailDialog";
import { CustomDivider } from "@/components/ui";

const projectsVariants = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.5,
            when: "beforeChildren",
            delayChildren: 0.2,
            staggerChildren: 1.5,
        },
    },
    hover: {},
};

export const Projects = (props) => {
    const dispatch = useDispatch();
    const handleAnimationComplete = (definition) => {
        dispatch(setFinishComponentLoad("projects"));
    };

    const projectsRef = useRef();
    const [timeLinePosition, setTimeLinePosition] = useState("alternate");

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 768) setTimeLinePosition("right");
            else setTimeLinePosition("alternate");
        }
        handleResize();
        window.addEventListener("resize", handleResize);
        return (_) => window.removeEventListener("resize", handleResize);
    }, []);

    const [openedProjectDialog, setOpenedProjectDialog] = useState(false);
    const [projectData, setProjectData] = useState(null);

    const handleCloseProjectDialog = () => {
        setOpenedProjectDialog(false);
    };

    const handleClickOpenProjectDialog = (e, data) => {
        setProjectData(data);
        setOpenedProjectDialog(true);
    };

    return (
        <ProjectsContainer
            ref={props.scrollTo}
            // animate={controls}
            initial={"hidden"}
            variants={projectsVariants}
            whileInView={"visible"}
            viewport={{ once: true, amount: 0.2 }}
            // onAnimationComplete={ definition => handleAnimationComplete(definition)}
        >
            <CustomDivider text="Projects" />

            <Timeline position={timeLinePosition} ref={projectsRef}>
                {ProjectsList.map((project, index) => (
                    <ProjectModel
                        projectData={project}
                        index={index}
                        key={index}
                        parentRef={projectsRef}
                        handleClickOpenProjectDialog={
                            handleClickOpenProjectDialog
                        }
                        handleLoadingComplete={() =>
                            index === ProjectsList.length - 1 &&
                            handleAnimationComplete()
                        }
                    />
                ))}
            </Timeline>

            <ProjectDetailDialog
                project={projectData}
                openedDialog={openedProjectDialog}
                // onDialogOpen={handleClickOpenProjectDialog}
                onDialogClose={handleCloseProjectDialog}
            />
        </ProjectsContainer>
    );
};

const ProjectsContainer = styled(motion.div)`
    && {
    }
    /* && .MuiPaper-root-MuiDialog-paper {
        background: red;
    } */
`;
