import { Divider, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'

export const CustomDivider = ({text, style}) => {
    return (
        <StyledDivider style={style}>
            <Divider textAlign="center">
                <Typography variant="h5" component="h2">
                    {text}              
                </Typography>
            </Divider>
        </StyledDivider>
    )
}

const StyledDivider = styled(motion.div)`
    && {
        margin: 150px 20% 75px 20%;
    }
    && .MuiTypography-root {
        border-radius: 0px;

        /* color: #fff;
        background: var(--primary-color);
        box-shadow: -4px -4px 0px 0px rgba(var(--rgb-primary-color), 0.4), -7px -7px 0px 0px rgba(var(--rgb-primary-color), 0.17), 0px 10px 13px -7px rgba(var(--rgb-primary-color), 1); */

        color: var(--secondary-color); 
        background: rgb(230, 228, 228);
        box-shadow: 
                    -4px -4px 0px 0px rgba(230, 228, 228, 0.4), 
                    -7px -7px 0px 0px rgba(230, 228, 228, 0.17) 
                    //0px 10px 13px -7px rgba(230, 228, 228, 1)
                    ;

        transition: 0.6s;

        /* text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15); */
        font-weight: bolder;
        font-family: var(--portfolio-font);
        letter-spacing: 1px;
        /* font-size: 1em; */
        padding: 15px;

        /* overflow: hidden;
        white-space: nowrap;
        width: 0;
        animation: typing 3s steps(20, end) forwards; */
    }

    @keyframes typing {
        from { width: 0 }
        to { width: 100% }
    }

    @media screen and (max-width: 768px) {
        && {
        margin: 30px 1%;
        margin: 150px 1% 75px 1%;
    }
    }
`
