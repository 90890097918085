import React from "react";
import styled from "styled-components";
import { Typography, Box, Grid } from "@mui/material";
import { Masonry } from "@mui/lab";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
// import Tilt from 'react-parallax-tilt'

import { useDispatch } from "react-redux";
import { setFinishComponentLoad } from "@/context/redux.loading";

import fullSkillsList from "@/data/SkillsList";
import { CustomDivider } from "@/components/ui";
import { SkillItem } from "./SkillItem";

var skillsList = {};
Object.keys(fullSkillsList).map((category, key) => {
    const cat = {};
    skillsList = {
        ...skillsList,
        [category]: fullSkillsList[category].filter(
            (skill, index) => !(skill?.show == false) ?? true
        ),
    };
});

const skillsVariants = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.2,
            type: "spring",
            // damping: 10,
            // stiffness: 100,
            bounce: 0.4,
            when: "beforeChildren",
            delayChildren: 0.2,
            staggerChildren: 0.4,
        },
    },
    hover: {},
    // animationOne: { background: 'red', transition: { duration: 1, when: "beforeChildren", delayChildren: 0.2, staggerChildren: 0.4 } }
};

const skillsCategoryVariants = {
    hidden: { opacity: 0, x: 0, scale: 1, rotateY: 90 },
    visible: {
        opacity: 1,
        x: 0,
        rotateY: 0,
        transition: {
            duration: 0.3,
            type: "tween",
            // bounce: 0.1,
            // damping: 10,
            // stiffness: 100,
            when: "beforeChildren",
            delayChildren: 1,
            staggerChildren: 0.4,
        },
    },
    hover: {},
    // animationOne: { scale: 1.3, background: 'black', transition: { duration: 1, when: "beforeChildren", delayChildren: 1, staggerChildren: 0.4 } }
};

export const Skills = (props) => {
    const dispatch = useDispatch();
    const handleAnimationComplete = (definition) => {
        dispatch(setFinishComponentLoad("skills"));
    };

    // filter, primaryColor, secondaryColor
    const { darkTheme } = useSelector((state) => state.theme);
    let animationIndex = 0;

    return (
        <motion.div
            ref={props.scrollTo}
            initial="hidden"
            // animate={controls}
            variants={skillsVariants}
            whileInView={"visible"}
            viewport={{ once: true, amount: 0.2 }}
            onAnimationComplete={(definition) =>
                handleAnimationComplete(definition)
            }
        >
            <CustomDivider text="Skills" />

            <SkillsContainer
                columns={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 3 }}
                spacing={{ xs: 1, sm: 1, md: 2, lg: 3, xl: 3 }}
            >
                {Object.keys(skillsList).map((category, key) => {
                    return (
                        <SkillsCategoryItem
                            key={key}
                            variants={skillsCategoryVariants}
                        >
                            <SkillsCategoryTitle>
                                <Typography
                                    variant="h5"
                                    component="h5"
                                    sx={{
                                        filter: darkTheme
                                            ? "invert(1)"
                                            : "invert(0)",
                                    }}
                                >
                                    {category}
                                </Typography>
                            </SkillsCategoryTitle>
                            <StylledSkillsList>
                                {skillsList[category].map((skill, index) => {
                                    animationIndex++;
                                    return (
                                        // <Tilt tiltMaxAngleX={30} tiltMaxAngleY={30} tiltReverse={false} >
                                        <SkillItem
                                            label={skill.label}
                                            icon={skill.icon}
                                            key={index}
                                            i={animationIndex}
                                        />
                                        // </Tilt>
                                    );
                                })}
                            </StylledSkillsList>
                        </SkillsCategoryItem>
                    );
                })}
            </SkillsContainer>
        </motion.div>
    );
};

const SkillsContainer = styled(Masonry)`
    && {
        /* display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap; */
        align-content: center;
        padding: 0px;
        margin: 0px;
    }
`;

const SkillsCategoryItem = styled(motion.div)`
    && {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: auto;
        margin: 10px;
        padding: 0rem;
        transition: 0.6s;
        /* color: var(--secondary-color);
        background: rgb(230, 228, 228);
        box-shadow: -4px -4px 0px 0px rgba(230, 228, 228, 0.4), -7px -7px 0px 0px rgba(230, 228, 228, 0.17), 0px 10px 13px -7px rgba(230, 228, 228, 1); */

        color: #fff;
        background: var(--primary-color);
        box-shadow: -4px -4px 0px 0px rgba(var(--rgb-primary-color), 0.4),
            -7px -7px 0px 0px rgba(var(--rgb-primary-color), 0.17),
            0px 10px 13px -7px rgba(var(--rgb-primary-color), 1);
    }
    /* &&:nth-of-type(2){
        color:#fff;
        background: var(--secondary-color);
        box-shadow: -4px -4px 0px 0px rgba(var(--rgb-secondary-color), 0.4), -7px -7px 0px 0px rgba(var(--rgb-secondary-color), 0.17), 0px 10px 13px -7px rgba(var(--rgb-secondary-color), 1);
    } */

    && .MuiAvatar-root {
        border-radius: 0%;
    }
`;

const SkillsCategoryTitle = styled(Box)`
    && {
        transition: 0.6s;
        padding: 10px 0px;
        width: 100%;
        text-align: center;
        background: #ffffff4a;
        backdrop-filter: blur(0px);
        margin: 0px 0px 20px 0px;
    }
    && .MuiTypography-root {
        color: var(--secondary-color);
        font-family: var(--portfolio-font);
        color: #fff;
        /* text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15); */

        /* text-shadow: 0 1px 0 #444444, 0 2px 0 #474747, 0 3px 0 #555, 0 4px 0 #575757, 0 5px 0 #666, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15); */
        font-weight: bolder;
        letter-spacing: 4px;
    }
`;

const StylledSkillsList = styled(Grid)`
    && {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
    }
`;
