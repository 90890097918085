
import { useInView } from 'react-intersection-observer'
import { useDispatch } from 'react-redux'
import { setCurrentComponent } from '@/context/redux.loading'

export const useHandleScroll = ({isScrolling, viewIndex}) => {

    const dispatch = useDispatch()

    const handleInView = (inView, entry)=>{
        inView && !isScrolling && dispatch(setCurrentComponent(viewIndex)) 
    }

    const { ref, inView, entry } = useInView({ threshold: 0.2, skip: isScrolling, onChange: handleInView })


    return { ref, inView, entry }

}
