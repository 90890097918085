/*

      _            _       _             
     | |          | |     | |            
   __| | _____   _| | ___ | | _____ _ __ 
  / _` |/ _ \ \ / / |/ _ \| |/ / _ \ '__|
 | (_| |  __/\ V /| | (_) |   <  __/ |   
  \__,_|\___| \_/ |_|\___/|_|\_\___|_|   
                                         
                                         

*/
export default {
    typeWriter: () => {
        const titleText = "Dev-Loker";
        const speed = 500;
        setInterval(() => {
            let titleRest = titleText.substring(
                document.title.length,
                titleText.length
            );
            let newPageTitle = "";
            if (titleRest.length > 0)
                newPageTitle = document.title + titleRest.charAt(0);
            else newPageTitle = titleText.charAt(0);
            document.title = newPageTitle;
        }, speed);
    },
    developerName: () => {
        let name = `
        \n
              _            _       _             
             | |          | |     | |            
           __| | _____   _| | ___ | | _____ _ __ 
          / _\` |/ _ \\ \\ / / |/ _ \\| |/ / _ \\ '__|
         | (_| |  __/\ V /| | (_) |   <  __/  |   
          \\__,_|\\___| \\_/ |_|\\___/|_|\\_\\___|_|    
        \n`;
        // console.log(name)
        return name;
    },

    logVisitor: () => {
        try {
            // "proxy": "http://localhost:4000",
            // const REACT_APP_LOG_URL = `http://localhost:4000/logVisitor`;
            // const REACT_APP_LOG_URL = `/api/logVisitors`;
            const REACT_APP_LOG_URL = `/.netlify/functions/logVisitors`;
            fetch(REACT_APP_LOG_URL, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ token: "DEVLOKER_TOKEN" }),
            })
                .then((data) => {})
                .catch((err) => {});
        } catch (error) {}
    },
};
