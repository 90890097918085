import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay, EffectCards } from "swiper"; // Parallax EffectCards  EffectCube , EffectFlip, EffectCards
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

export const PhotoSlider = ({ photosList }) => {
    const { darkTheme } = useSelector((state) => state.theme);

    return (
        <>
            <SwiperSlideContainer>
                <ScreenshotsSwiper
                    effect={"cards"}
                    modules={[
                        EffectCards,
                        Pagination,
                        Navigation /*, Autoplay*/,
                    ]}
                    // effect={"cube"}
                    // cubeEffect={{
                    // shadow: true,
                    // slideShadows: true,
                    // shadowOffset: 20,
                    // shadowScale: 0.94,
                    // }}
                    // modules={[EffectCube, Pagination, Navigation, Autoplay]}

                    // effect={"flip"}
                    // modules={[EffectFlip, Pagination, Navigation, Autoplay]}

                    loop={true}
                    autoplay={{ delay: 2000 }}
                    pagination={{ clickable: true }}
                    grabCursor={true}
                    navigation={true}
                    className="mySwiper"
                >
                    {photosList.map(
                        (photo, index) =>
                            photo && (
                                <SwiperSlide key={index} bg={photo}>
                                    <SwiperImage
                                        src={photo}
                                        style={{
                                            filter: darkTheme
                                                ? "invert(1)"
                                                : "invert(0)",
                                        }}
                                    />
                                </SwiperSlide>
                            )
                    )}
                </ScreenshotsSwiper>
            </SwiperSlideContainer>
        </>
    );
};

const SwiperSlideContainer = styled(SwiperSlide)`
    && {
        /* background-color: rgba(var(--rgb-primary-color), 0.09); */
        border: 0px solid rgba(var(--rgb-secondary-color), 0.99);
        /* border-top-width: 15px; */
        /* border-bottom-width: 15px; */
        border-bottom-color: rgba(var(--rgb-secondary-color), 0);
    }
    &&:hover {
    }
    &&:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        background-color: rgba(var(--rgb-secondary-color), 0.1);
    }
    &&:hover:after {
        background-color: rgba(var(--rgb-secondary-color), 0);
    }
`;

const ScreenshotsSwiper = styled(Swiper)`
    && .swiper-slide {
        width: 100%;
        /* max-height: calc(100vh - 60px); */
        height: 100%;
        /* background-color: #ffffff88; */
    }
    && img {
        max-width: 100%;
        height: auto;
    }
    &&:hover > .swiper-button-prev,
    &&:hover > .swiper-button-next {
        visibility: visible;
    }
    && .swiper-button-prev,
    && .swiper-button-next {
        visibility: hidden;
        color: #fff;
        background: rgba(var(--rgb-secondary-color), 0.99);
        padding: 10px;
        position: absolute;
        top: 80%;
        box-shadow: -4px -4px 0px 0px rgba(var(--rgb-secondary-color), 0.4),
            -7px -7px 0px 0px rgba(var(--rgb-secondary-color), 0.17),
            0px 10px 13px -7px rgba(var(--rgb-secondary-color), 1);
    }
    && .swiper-button-prev:hover,
    && .swiper-button-next:hover {
        background: rgba(var(--rgb-primary-color), 0.99);
        box-shadow: -4px -4px 0px 0px rgba(var(--rgb-primary-color), 0.4),
            -7px -7px 0px 0px rgba(var(--rgb-primary-color), 0.17),
            0px 10px 13px -7px rgba(var(--rgb-primary-color), 1);
    }
    @media screen and (max-width: 768px) {
        && .swiper-button-prev,
        && .swiper-button-next {
            visibility: hidden;
        }
    }

    && .swiper-pagination-bullet {
        background: #fff;
        opacity: 1;
    }
    && .swiper-pagination-bullet-active {
        background: var(--primary-color);
    }
    && .swiper-pagination {
        background: rgba(var(--rgb-secondary-color), 0.99); // 4a
        bottom: 0px;
        bottom: -0px;
        position: sticky;
    }
`;

const SwiperImage = styled("div")`
    && {
        /* background-color: var(--secondary-color); */
        background: #fff;
        background-image: url(${(props) => props.src});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: auto;
        max-height: calc(100vh - 60px);
        height: 500px;
    }
`;
