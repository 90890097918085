import React from 'react'
import styled from 'styled-components'
import { Avatar, Chip, Tooltip, Zoom } from '@mui/material'
import { motion } from 'framer-motion'
// import Tilt from 'react-parallax-tilt'
import { useSelector } from 'react-redux'


const skillVariants = {
    hidden: { opacity: 0, y: 0, scale: 0, rotateX: 90 },
    visible: (i)=>{
        //console.log(i)
        return {
            opacity: 1,
            scale: 1,
            y: 0,
            rotateX: 0,
            transition: {
                //duration: 0.5,
                type: 'tween',
                //bounce: 0.3,
                // damping: 10,
                // stiffness: 100,
                delay: 1.5+i*0.1
            }
        } 
    },
    hover: {
        rotateZ: [0, 0, -15, 10, -10, 6, -4, 0, 0],
        transition :{
            duration: 1,
            times: [0, 0.07, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 1],
            type: 'tween'
        }
    }
    // animationOne: { scale: 1.3, background: 'green', transition: { duration: 1, when: "beforeChildren", delayChildren: 0.2, staggerChildren: 1.5 } }
}


export const SkillItem = ({label, icon, i}) => {

    const { darkTheme } = useSelector(state=> state.theme)
    
    // initial='hidden' whileHover='hover'
    return (
        <StyledItem theme={`${darkTheme}`} initial='hidden' animate='visible' variants={skillVariants} custom={i} whileHover='hover' >
            <Tooltip title={label} TransitionComponent={Zoom} >
                <Chip
                    avatar={
                        <Avatar sx={{ filter : darkTheme? 'invert(1)': 'invert(0)' }}  >
                            {icon}
                        </Avatar>
                    }
                    label={label}
                    variant="outlined"
                />
            </Tooltip>
        </StyledItem>
    )
}

const StyledItem = styled(motion.div)`
    && .MuiChip-root {
        border-radius: 0;
        border: none;
        background: rgba(var(--rgb-primary-color), 0.3);
        margin: 6px;
        padding: 30px 10px 30px 0px;
        color: var(--secondary-color);
        box-shadow: -4px -4px 0px 0px rgba(var(--rgb-primary-color), 0.1),
                    -7px -7px 0px 0px rgba(var(--rgb-primary-color), 0.2),
                    0px 10px 13px -7px rgba(var(--rgb-primary-color), 0.2);
    }
    && .MuiChip-root:hover {
        background: rgba(var(--rgb-secondary-color), 0.99);
        box-shadow: -4px -4px 0px 0px rgba(var(--rgb-secondary-color), 0.4),
                    -7px -7px 0px 0px rgba(var(--rgb-secondary-color), 0.2),
                    0px 10px 13px -7px rgba(var(--rgb-secondary-color), 1);
        cursor: pointer;
    }
    && .MuiAvatar-root {
        background: transparent;
        width: 55px;
        height: 55px;
    }
    && svg{
        fill: #ffffff;
    }
    && .MuiChip-label {
        display: none;
        visibility: hidden;
    }
    && .MuiChip-root:hover{
        animation: wiggle 1s ease-out 1;
    }
    && .MuiChip-root:hover svg{
        fill: ${({theme})=> theme===`true`?`#000000`:`#ffffff`} ;
    }
`

