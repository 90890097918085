import { createSlice } from "@reduxjs/toolkit";

export const LoadingSlice = createSlice({
    name: "loading",
    initialState: {
        finishLoad: {
            intro: false,
            profile: false,
            projects: false,
            skills: false,
            testimonials: false,
            contacts: false,
        },
        currentComponent: 0,
    },
    reducers: {
        setFinishComponentLoad: (state, action) => {
            let tmp = { ...state.finishLoad };
            tmp[action.payload] = true;
            state = { ...state, finishLoad: tmp };
            return state;
        },
        setFinishIntroLoad: (state, action) => {
            state = {
                ...state,
                finishLoad: { ...state.finishLoad, intro: action.payload },
            };
            return state;
        },
        setCurrentComponent: (state, action) => {
            state = { ...state, currentComponent: action.payload };
            return state;
        },
    },
});

export const {
    setFinishIntroLoad,
    setFinishComponentLoad,
    setCurrentComponent,
} = LoadingSlice.actions;
