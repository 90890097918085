
import { Box, Grid } from '@mui/material'
import React, { useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { motion } from 'framer-motion'

// import profilePhoto_0 from '@/assets/logo/profile-photo_00.png'
import profilePhoto_1 from '@/assets/logo/profile-photo_01.png'
import logo from '@/assets/logo/logo192.png'
import { QRCode } from 'react-qrcode-logo'

// const hoverImageAnimation = {
//     skewX: ['0deg', '5deg', '0deg', '5deg', '0deg'],
//     y: [0, -20, -40, -20, 0],
//     transition: {
//         times: [0, 0.25, 0.5, 0.75, 1],
//         duration: 0.5,
//         ease: "easeOut",
//         delay: 0.2//*i
//     }
// }
// const rubberBand = {
//     scaleX: [1, 1.25, 0.75, 1.15, 0.95, 1.05, 1],
//     scaleY: [1, 0.75, 1.25, 0.85, 1.05, 0.95, 1],
//     transition: {
//         times: [0, 0.3, 0.4, 0.5, 0.65, 0.75, 1],
//         duration: 1,
//         ease: "easeOut",
//         delay: 0.2//*i
//     }
// }


const rightVariants = {
    hidden: { opacity: 0, x: '-100vw' },
    visible: (custom)=>{
        return {
            opacity: 1,
            x: 0,
            transition: {
                duration: 1,
                when: "beforeChildren",
                delayChildren: 0.0,
                staggerChildren: 0.8,
                delay: custom===2?4.5:2.5,
            }
        }
    },
    hover: {  }
}

const ProfilePhotoVariants = {
    hidden: { opacity: 0, x: '-100vw', scale: 1 },
    visible : (i)=>{
        return {
            x: 0,
            skewX: ['-20deg', '20deg', '-10deg', '10deg', '-5deg', '0deg'],
            //scale: [0.5, 0.6, 0.7, 0.8, 0.9, 1],
            // scale: 1,
            opacity: 1,
            // rotate: 360,
            transition: {
                duration: 1,
                // type: 'tween',
                // ease: 'easeInOut',
                times: [0, 0.6, 0.7, 0.8, 0.9, 1],
                type: 'spring',
                // stiffness: 600,
                // damping: 30,
                // mass: 0.75,
                bounce: 0.2,
                // delay : i*1,
                //repeat: 2
            }
        }
    },
    hover: {  }
}

export const RightSide = (props) => {
    // filter, primaryColor, secondaryColor
    const { darkTheme } = useSelector(state=> state.theme)

    useLayoutEffect(()=>{
        let canvas = document.getElementById("react-qrcode-logo");
        canvas.style.width = "100%";
        canvas.style.height = "100%";
    },[])

    return (
        <RightSideContainer variants={rightVariants} custom={props.order} >
            <Grid>
                <ProfilePhotoContainer>
                    <BackdropImageContainer className={`beforeImg boxImg`} variants={ProfilePhotoVariants} custom={0} />
                    <BackdropImageContainer className={`beforeImg boxImg`} variants={ProfilePhotoVariants} custom={1} />
                    <ImageContainer className={`Img boxImg`}
                        style={{filter : darkTheme? 'invert(1)': 'invert(0)'}} 
                        // onAnimationEnd={(e)=>{  }}
                        variants={ProfilePhotoVariants} custom={2}
                    >
                        <QRCode 
                            value="https://www.dev-loker.com" 
                            // fgColor={`rgb(${secondaryColor})`}
                            logoWidth={15}
                            // logoOpacity={0.4}
                            logoImage={logo}
                            removeQrCodeBehindLogo={true} 
                            qrStyle={'dots'} 
                            eyeRadius={5}
                            // theme={darkTheme}
                            // style={{ width: 300, height: 300 }}
                            // size={"auto"}
                        />
                    </ImageContainer>
                </ProfilePhotoContainer>          
            </Grid>
        </RightSideContainer>
    )
}

const RightSideContainer = styled(motion.div)`
    &&, && .MuiGrid-root{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
    }
`

const ProfilePhotoContainer = styled(Box)`
    && {
        position: relative;
        margin-left: 30px;
        display: flex;
        justify-content: center;
        /* align-items: flex-start; */
    }
    
    &&:hover  > .Img{
        animation: 0.5s linear 0s 1 alternate both hoverImageAnimation;
    }

    &&:hover  > .beforeImg:nth-child(1){
        animation: 0.5s linear 0.4s 1 alternate both hoverImageAnimation;
    }

    &&:hover  > .beforeImg:nth-child(2){
        animation: 0.5s linear 0.2s 1 alternate both hoverImageAnimation;
    }

    @media screen and (max-width: 600px) {
        && {
            margin-left: 20px;
        }
    }
    @keyframes hoverImageAnimation {
        0% {
            transform: skewX(0deg) translateY(0px);
        }
        25% {
            transform: skewX(5deg) translateY(-20px);
        }
        50% {
            transform: skewX(0deg) translateY(-40px);
        }
        75% {
            transform: skewX(5deg) translateY(-20px);
        }
        100% {
            transform: skewX(0deg) translateY(0px);
        }        
    }
`

const BoxImage = styled(motion.div)`
    &&  {
        min-width:300px;
        width: 60%;
        min-height: 300px;
        height: auto;
        border-width: 0px;
        padding: 0px;
        position: absolute;
    }
    @media screen and (max-width : 600px){
        && {
            min-width:200px;
            min-height: 200px;
        }
    }
`

const BackdropImageContainer = styled(BoxImage)`
    && {
        display: flex;
        justify-content: center;
        align-items: center;
        /* position: relative; */
    }
    &&:nth-child(1){
        background: var(--secondary-color);
        box-shadow: 10px 10px 50px 2px var(--secondary-color);
        /* top: -20px; left: -20px; */
        /* transform: translate(-20px, -20px); */
        margin-top: -20px; margin-left: -40px;
    }
    &&:nth-child(2){
        background: var(--primary-color);
        box-shadow: 10px 10px 50px 2px var(--primary-color);
        /* top: -10px; left: -10px; */
        /* transform: translate(-10px, -10px); */
        margin-top: -10px; margin-left: -20px;
    }
    @media screen and (max-width: 600px) {
        &&:nth-child(1){
            /* top: -10px; left: -10px; */
            margin-top: -10px; margin-left: -20px;
        }
        &&:nth-child(2){
            /* top: -05px; left: -05px; */
            margin-top: -05px; margin-left: -10px;
        }
    }
`

const ImageContainer = styled(BoxImage)`
    && {
        background-color: #ffffff88; // #c0c0c085 #ffffff88
        backdrop-filter: blur(2px);
        /* background-color: var(--primary-color); */
        background-image: url(${profilePhoto_1});//, linear-gradient(180deg, #1cb5e088 0%, var(--secondary-color) 100%);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-blend-mode: luminosity;
        background-origin: content-box;
        /* box-shadow: 0px 0px 10px 1px #b8b5b5; */
        box-shadow: 10px 10px 50px 2px #c0c0c085;
        border: 1px #fff solid;
        /* border-radius: 0% 100% 100% 100%; */
        position: relative;
        /* animation:forest-night 10s infinite; */
        display: flex;
        justify-content: center;
        align-items: center;
        /* top:0; left:0; */
    }
    &&:hover{
        background-image: none;
    }
    &&:hover > #react-qrcode-logo {
        visibility: visible;
        opacity: 1;
        transition: 0.5s;
    }

    && canvas#react-qrcode-logo {
        visibility: hidden;
        opacity: 0;transition: 0.5s; 
    }


    @-webkit-keyframes forest-night {
    0% {
        filter:hue-rotate(0deg) brightness(0%);
    }
    10% {
        filter:hue-rotate(0deg) brightness(50%);
    }
    20% {
        filter:hue-rotate(0deg) brightness(100%);
    }
    50% {
        filter:hue-rotate(0deg) brightness(2);
    }
    70% {
        filter:hue-rotate(220deg) brightness(50%);
    }
    100% {
        filter:hue-rotate(0deg) brightness(0%);
    }
    }
    @keyframes forest-night {
    0% {
        filter:hue-rotate(0deg) brightness(0%);
    }
    10% {
        filter:hue-rotate(0deg) brightness(50%);
    }
    20% {
        filter:hue-rotate(0deg) brightness(100%);
    }
    50% {
        filter:hue-rotate(0deg) brightness(2);
    }
    70% {
        filter:hue-rotate(220deg) brightness(50%);
    }
    100% {
        filter:hue-rotate(0deg) brightness(0%);
    }
    }
`