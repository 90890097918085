import React from 'react'
import styled from 'styled-components'

export const CustomFooter = () => {
    return (
        <StyledFooter>
            
        </StyledFooter>
    )
}


const StyledFooter = styled('div')`
    && {
        width: 100%;
        height: 250px;
    }
`
