import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper"; // Parallax EffectCards  EffectCube , EffectFlip, EffectCards
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import { useDispatch } from "react-redux"; // useSelector
import { setFinishComponentLoad } from "@/context/redux.loading";

import { CustomDivider } from "@/components/ui";

import testimonialsList from "@/data/TestimonialsList";
// import { TestimonialItem } from '@/components/testimonials'

const testimonialsVariants = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.5,
            when: "beforeChildren",
            delayChildren: 0.2,
            staggerChildren: 1.5,
        },
    },
    hover: {},
};

const swiperVariants = {
    hidden: { opacity: 0, y: "100vh" },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            // duration: 1,
            type: "spring",
            bounce: 0.4,
        },
    },
    hover: {},
};

export const Testimonials = (props) => {
    // const { darkTheme } = useSelector(state=> state.theme)

    const dispatch = useDispatch();
    const handleAnimationComplete = (definition) => {
        dispatch(setFinishComponentLoad("testimonials"));
    };

    return (
        <TestimonialsContainer
            ref={props.scrollTo}
            // animate={controls}
            initial={"hidden"}
            variants={testimonialsVariants}
            whileInView={"visible"}
            viewport={{ once: true, amount: 0.2 }}
            onAnimationComplete={(definition) =>
                handleAnimationComplete(definition)
            }
        >
            <CustomDivider text="Upwork Testimonials" />

            <motion.div variants={swiperVariants}>
                <SwiperSlideContainer>
                    <TestimonialsSwiper
                        // effect={"cards"}
                        // modules={[EffectCards, Pagination, Navigation, Autoplay]}

                        // slidesPerView={3}
                        // spaceBetween={10}
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 30,
                            },
                        }}
                        modules={[Pagination, Navigation]}
                        // effect={"cards"}
                        // modules={[EffectCards, Pagination, Navigation, Autoplay]}

                        // effect={"cube"}
                        // cubeEffect={{
                        // shadow: true,
                        // slideShadows: true,
                        // shadowOffset: 20,
                        // shadowScale: 0.94,
                        // }}
                        // modules={[EffectCube, Pagination, Navigation, Autoplay]}

                        // effect={"flip"}
                        // modules={[EffectFlip, Pagination, Navigation, Autoplay]}

                        loop={true}
                        autoplay={{ delay: 4000 }}
                        pagination={{ clickable: true }}
                        grabCursor={true}
                        navigation={true}
                        className="mySwiper"
                    >
                        {testimonialsList.map((photo, index) => (
                            <SwiperSlide key={index} bg={photo}>
                                <SwiperImage src={photo} />
                            </SwiperSlide>
                            // <TestimonialItem key={index} photo={photo} style={{ filter: darkTheme? 'invert(1)':'invert(0)' }} />
                        ))}
                    </TestimonialsSwiper>
                </SwiperSlideContainer>
            </motion.div>
        </TestimonialsContainer>
    );
};

const TestimonialsContainer = styled(motion.div)`
    && {
    }
`;

const SwiperSlideContainer = styled(SwiperSlide)`
    && {
        /* background: #fff; */
        /* padding-top: 15px; */
    }
    &&:hover {
    }
    &&:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        /* background-color: rgba(var(--rgb-secondary-color), 0.1); */
    }
    &&:hover:after {
        /* background-color: rgba(var(--rgb-secondary-color), 0.0); */
    }
`;

const TestimonialsSwiper = styled(Swiper)`
    && {
        padding: 0px;
        /* border-top: 15px rgb(128, 140, 128) solid; */
        border-top: 15px rgba(var(--rgb-secondary-color), 0.99) solid;
        box-shadow: 0px 2px 7px 1px rgba(var(--rgb-secondary-color), 0.5);
        box-shadow: -4px -4px 0px 0px rgba(var(--rgb-secondary-color), 0.4),
            -7px -7px 0px 0px rgba(var(--rgb-secondary-color), 0.17),
            0px 10px 13px -7px rgba(var(--rgb-secondary-color), 1);
        background: #fff;
        /* background: rgba(var(--rgb-secondary-color), 0.7); */
    }
    && .swiper-slide {
        width: 100%;
        /* max-height: calc(100vh - 60px); */
        height: 100%;
        /* background-color: #ffffff88; */
    }
    && .swiper-slide:nth-child(odd) > div {
        filter: hue-rotate(-90deg) invert(1);
    }
    && .swiper-slide:nth-child(even) > div {
        filter: hue-rotate(90deg) invert(0);
    }

    &&:hover > .swiper-button-prev,
    &&:hover > .swiper-button-next {
        visibility: visible;
    }
    && .swiper-button-prev,
    && .swiper-button-next {
        visibility: hidden;
        /* transition: ease-in-out 0.1s; */
        color: #fff;
        /* background: rgba(255,255,255, 0.5); */
        background: rgba(var(--rgb-secondary-color), 0.99);
        padding: 10px;
        position: absolute;
        top: 80%;
        box-shadow: -4px -4px 0px 0px rgba(var(--rgb-secondary-color), 0.4),
            -7px -7px 0px 0px rgba(var(--rgb-secondary-color), 0.17),
            0px 10px 13px -7px rgba(var(--rgb-secondary-color), 1);
    }
    && .swiper-button-prev:hover,
    && .swiper-button-next:hover {
        background: rgba(var(--rgb-primary-color), 0.99);
        box-shadow: -4px -4px 0px 0px rgba(var(--rgb-primary-color), 0.4),
            -7px -7px 0px 0px rgba(var(--rgb-primary-color), 0.17),
            0px 10px 13px -7px rgba(var(--rgb-primary-color), 1);
    }
    && .swiper-pagination-bullet {
        opacity: 0.8;
        background: #fff;
    }
    && .swiper-pagination-bullet-active {
        background: var(--primary-color);
        opacity: 0.99;
    }
    && .swiper-pagination {
        /* background:  rgba(255,255,255, 0.5); */
        background: rgba(var(--rgb-secondary-color), 0.99);
        bottom: 0px;
    }
`;

const SwiperImage = styled("div")`
    && {
        /* background-color: var(--secondary-color); */
        background: #001800;
        background-image: url(${(props) => props.src});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: auto;
        /* height:  calc(100vh - 60px); */
        height: 314px; // 628   314     500
    }
`;
