import React from 'react'
import styled from 'styled-components'
import { Box, Divider, Grid, Typography } from '@mui/material'
import { motion } from 'framer-motion'

import { ContactsAccountsIcons } from '@/components/contacts'
import { DeveloperName } from '@/components/ui'


const leftVariants = {
    hidden: { opacity: 0 },
    visible: { 
        opacity: 1,
        transition: {
            duration: 1,
            type: 'tween',
            ease: 'easeInOut',
            when: "beforeChildren",
            delayChildren: 0,
            staggerChildren: 0.8
        }
    },
    hover: {  }
}

const textVariants = {
    hidden: { opacity: 0, x: '-100vw' },
    visible: (custom)=>{
        return { 
            opacity: 1,
            x: 0,
            transition: {
                // duration: 1,
                type:"spring",
                bounce:0.3,
                when: "beforeChildren",
                delayChildren: 0,
                staggerChildren: 0.8,
                // delay: custom===2?2.5:0,
            },
        } 
    },
    hover: {

    }
}



export const LeftSide = (props) => {
    // item xs={12} sm={12} md={6} lg={6} xl={6}
    return (
        <LeftSideContainer variants={leftVariants} custom={props.order} >
            <Grid  >
                <Box sx={{ my: 3, mx: 2 }}>
                    <motion.div variants={textVariants} >
                        <Typography className='hi' variant="h3" component="h2" >
                            Hi, i'm Khaled
                        </Typography>
                    </motion.div>
                    <motion.div variants={textVariants} >
                        <Typography className='knownas' variant="h5" component="h6" >
                            Known as
                        </Typography>
                    </motion.div>
                </Box>
                <motion.div variants={textVariants} >
                    <Divider textAlign="center">
                        <DeveloperName />
                    </Divider>
                </motion.div>

                <motion.div variants={textVariants} custom={props.order} >
                    <ContactsAccountsIcons />
                </motion.div>
                
            </Grid>
        </LeftSideContainer>
    )
}


const LeftSideContainer = styled(motion.div)`
    &&, && .MuiGrid-root{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
    }
    && .hi {
        color: var(--secondary-color);
        font-weight: bolder;
        font-family: 'Dancing Script', cursive;
        text-align: center;
    }
    && .knownas {
        text-align: center;
        margin-top: 15px;
        /* font-family: cursive, fantasy, 'Fugaz One', cursive; */
        font-family: var(--portfolio-font);
        letter-spacing: 0.6rem;
        color: #c7c5c5;
        /* text-shadow: .1em .1em 0 hsl(200 50% 30%); */
        /* text-shadow: -18px 8px 18px #b4bbbb; */
    }

    && .developerName {
        transform: scale(0.8);
    }
    && .developerName:hover{
        animation: rubberBand 1s linear 1;
    }
    @media screen and (max-width : 768px){
        && {
            margin-top: 60px;
        }
    }
`
