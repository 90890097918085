import React from 'react'
import styled from 'styled-components'
import { SwiperSlide } from "swiper/react"


export const TestimonialItem = ({photo}) => {
    return (
        <SwiperSlide bg={photo} >
            <SwiperImage src={photo} alt={``} />
            {/* <img src={photo} alt={} /> */}
            {/* <div style={{ background: `url('${photo}')`, height: 500 }} ></div> */}
            {/* <>{photo}</> */}
        </SwiperSlide>
    )
}


const SwiperImage = styled('div')`
    && {
        /* background-color: var(--secondary-color); */
        background-color: #001800;
        background-image: url(${(props) => props.src});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: auto;
        /* max-height:  calc(100vh - 60px); */
        height: 500px;
    }
`
