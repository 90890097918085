// var dotenv = require('dotenv')
// var dotenvExpand = require('dotenv-expand')

// var myEnv = dotenv.config()
// dotenvExpand.expand(myEnv)

// console.log(process.env)

// export default {
//     SERVICE_ID: process.env.REACT_APP_EMAILJS_SERVICE_ID,
//     TEMPLATE_ID: process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
//     PUBLIC_KEY: process.env.REACT_APP_EMAILJS_PUBLIC_KEY
// }

export default {
    SERVICE_ID: "service_9dgrg4j",
    TEMPLATE_ID: "template_bb9k9oj",
    PUBLIC_KEY: "ji4Y25fuu_zTWnYfg"
}