import { Fab } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

const CustomFab = ({label, icon, style, onClick}) => {
    return (
        <StyledFab size="small" aria-label={label} style={style} onClick={onClick} >
            {icon}
        </StyledFab>
    )
}

const StyledFab = styled(Fab)`
    && {
        position: fixed;
        right: 16px;
        background: var(--secondary-color);
        color: #fff;
        border-radius: 0px;
        /* border: 4px var(--primary-color) solid; */
        
        transition: all 0.5s ease;

        box-shadow: 0px 0px 10px 1px #fff;
    }
    &&:hover{
        color: var(--secondary-color);
        background: var(--primary-color);
    }
`
export {StyledFab, CustomFab}