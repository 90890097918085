import React, { forwardRef, useLayoutEffect } from "react";
import styled from "styled-components";
import { Container, Box, Typography, SvgIcon } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import {
    TimelineItem,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
} from "@mui/lab";
//  TimelineOppositeContent

import { addElement, updateElement } from "@/context/redux.elements";

const duration = 0.6;
const projectItemVariants = {
    // hidden: (i)=>{ return { opacity: 0, x: i%2===0? `800px`:`-800px` } } ,
    hidden: { opacity: 0, y: 100 },
    visible: (i, restAnimation) => {
        return {
            opacity: 1,
            x: 0,
            y: 0,
            transition: {
                duration: duration,
                type: "spring",
                bounce: 0.3,
                delay: restAnimation * duration,
                // when: "beforeChildren",
                // delayChildren: 0.2,
                // staggerChildren: 1.5
            },
        };
    },
    hover: {},
};

export const ProjectModel = forwardRef(
    (
        {
            projectData: project,
            index,
            handleClickOpenProjectDialog,
            handleLoadingComplete,
        },
        ref
    ) => {
        const { darkTheme } = useSelector((state) => state.theme);

        const elements = useSelector((state) => state.elements);
        const dispatch = useDispatch();

        const { ref: ProjectItemReference, inView: isInView } = useInView({
            threshold: 0.2,
            triggerOnce: true, // skip: !inViewObserverActive, initialInView: true//root: parentRef.current,
        });
        const controls = useAnimation();
        useLayoutEffect(() => {
            // if(index==0) setOpenedProjectDialog(true)
            dispatch(addElement({ index: index, animationComplete: false }));
            if (isInView) {
                const restAnimation = elements.filter(
                    (elm) => elm.index < index && !elm.animationComplete
                ).length;
                controls.start((i) => {
                    return projectItemVariants.visible(i, restAnimation);
                });
            }
        }, [isInView]);

        const handleAnimationComplete = () => {
            // console.log(`${index} : onAnimationEnd`)
            dispatch(updateElement({ index: index, animationComplete: true }));
            handleLoadingComplete();
        };

        return (
            <ProjectItem
                ref={ProjectItemReference}
                animate={controls}
                initial="hidden"
                variants={projectItemVariants}
                custom={index}
                // whileInView={"visible"}
                // viewport={{once:true, amount:0.2}}
                onAnimationComplete={handleAnimationComplete}
            >
                <TimelineItem>
                    {/* <TimelineOppositeContent
                    // sx={{ m: 'auto 0' }}
                    // variant="body2"
                >
                    <Typography variant="h6" component="h6" className={index%2===0? "left": "right"}>
                        {
                            project.date.toLocaleString('en-US', { month: 'short', year:'numeric' })
                        }
                    </Typography>
                </TimelineOppositeContent> */}
                    <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot
                            onClick={(e) =>
                                handleClickOpenProjectDialog(e, project)
                            }
                        >
                            {typeof project.logo === "object" ? (
                                <SvgIcon
                                    component={project.logo}
                                    inheritViewBox
                                    style={{
                                        filter: darkTheme
                                            ? "invert(1)"
                                            : "invert(0)",
                                    }}
                                />
                            ) : (
                                <img
                                    src={project.logo}
                                    alt={project.title}
                                    loading={project.title}
                                    style={{
                                        filter: darkTheme
                                            ? "invert(1)"
                                            : "invert(0)",
                                    }}
                                />
                            )}
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>

                    <TimelineContent
                        sx={{ py: "12px", px: 2 }}
                        onClick={(e) =>
                            handleClickOpenProjectDialog(e, project)
                        }
                    >
                        <Container>
                            {/* <Typography variant="h6" component="h6">
                            {project.date.toLocaleDateString("en-US")}
                        </Typography> */}
                            <Box className="projectTitle">
                                <Typography
                                    align="center"
                                    variant="h5"
                                    component="h5"
                                    sx={{
                                        filter: darkTheme
                                            ? "invert(1)"
                                            : "invert(0)",
                                    }}
                                >
                                    {project.title}
                                </Typography>
                            </Box>
                            <Box className="projectDescription">
                                <Typography align="justify">
                                    {project.shortDescription}
                                </Typography>
                            </Box>
                        </Container>
                    </TimelineContent>
                </TimelineItem>
            </ProjectItem>
        );
    }
);

const ProjectItem = styled(motion.div)`
    && {
    }
    && .MuiTimelineOppositeContent-root .MuiTypography-root {
        color: var(--secondary-color);
    }
    && .MuiTimelineOppositeContent-root .MuiTypography-root.left {
        text-align: right;
        margin-right: 40px;
        margin-top: 15px;
    }
    && .MuiTimelineOppositeContent-root .MuiTypography-root.right {
        text-align: left;
        margin-left: 20px;
        margin-top: 15px;
    }
    && .MuiTimelineItem-root {
    }
    &&:nth-of-type(even) .MuiTimelineItem-root {
        flex-direction: row-reverse;
    }
    &&:nth-of-type(even) .MuiTimelineContent-root {
        text-align: left;
        transform: translate(-40px, -2px);
    }
    &&:nth-of-type(odd) .MuiTimelineContent-root {
        transform: translate(20px, -2px);
    }
    && .MuiContainer-root {
        padding: 0rem;
        transition: 0.6s;
        cursor: pointer;
        background: var(--secondary-color);
        color: #fff;
        box-shadow: -4px -4px 0px 0px rgba(var(--rgb-secondary-color), 0.4),
            -7px -7px 0px 0px rgba(var(--rgb-secondary-color), 0.17),
            0px 10px 13px -7px rgba(var(--rgb-secondary-color), 1);
    }
    && .MuiContainer-root .projectTitle {
        transition: 0.6s;
        background: var(--primary-color);
        padding: 0.5rem;
        background: #ffffff4a;
    }
    && .MuiContainer-root .projectTitle .MuiTypography-root {
        color: #fff;
        font-weight: bolder;
        font-family: var(--portfolio-font);
        letter-spacing: 4px;
    }

    && .MuiContainer-root .projectDescription {
        padding: 2rem;
    }
    && .MuiContainer-root .projectDescription .MuiTypography-root {
        font-family: var(--portfolio-font);
        font-weight: 400;
    }
    && .MuiTimelineDot-root {
        transition: 0.6s;
        cursor: pointer;
        border-radius: 0;
        position: absolute;
        transform: translateX(-50%);
        background: rgb(230, 228, 228);
        box-shadow: -4px -4px 0px 0px rgba(230, 228, 228, 0.4),
            -7px -7px 0px 0px rgba(230, 228, 228, 0.17),
            0px 10px 13px -7px rgba(230, 228, 228, 1);
    }
    && .MuiTimelineDot-root img {
        width: 35px;
        height: 35px;
    }
    && .MuiTimelineDot-root svg {
        fill: var(--secondary-color);
        width: 35px;
        height: 35px;
    }
    && .MuiTimelineConnector-root {
        margin-top: 0px; // 12px
        background: #e6e4e4;
        width: 20px;
        transform: translateX(-10px);
    }
    &&:first-of-type .MuiTimelineConnector-root:first-of-type {
        margin-top: 10px;
    }

    &&:hover .MuiContainer-root {
        color: #fff;
        background: var(--primary-color);
        box-shadow: -4px -4px 0px 0px rgba(var(--rgb-primary-color), 0.4),
            -7px -7px 0px 0px rgba(var(--rgb-primary-color), 0.17),
            0px 10px 13px -7px rgba(var(--rgb-primary-color), 1);
    }
    &&:hover .projectTitle .MuiTypography-root {
        /* box-shadow: 40px -25px 6px 1px #ffffff; */
    }
    &&:hover .MuiTimelineDot-root {
        background: var(--primary-color);
        box-shadow: -4px -4px 0px 0px rgba(var(--rgb-primary-color), 0.4),
            -7px -7px 0px 0px rgba(var(--rgb-primary-color), 0.17),
            0px 10px 13px -7px rgba(var(--rgb-primary-color), 1);
    }
    &&:hover .MuiTimelineDot-root svg {
        fill: #fff;
        /* background: #fff; */
    }
    &&:nth-of-type(odd):hover .MuiTimelineDot-root {
        transition: 0.5s;
        z-index: 2;
        transform: translate(calc(100% + 5px), 0px) rotateZ(0deg); // 10px
    }
    &&:nth-of-type(odd):hover
        .MuiTimelineOppositeContent-root
        .MuiTypography-root {
        transition: 0.5s;
        transform: translate(calc(100% + 5px), 0px) rotateZ(0deg);
    }

    &&:nth-of-type(even):hover .MuiTimelineDot-root {
        transition: 0.5s;
        z-index: 2;
        transform: translate(calc(-100% - 55px), 0px) rotateZ(0deg); // -60px
    }
    &&:nth-of-type(even):hover
        .MuiTimelineOppositeContent-root
        .MuiTypography-root {
        transition: 0.5s;
        transform: translate(calc(-100% - 55px), 0px) rotateZ(0deg);
    }

    @media screen and (max-width: 768px) {
        && .MuiTimelineItem-root:before {
            flex: 0;
        }
        && .MuiTimelineOppositeContent-root {
            visibility: hidden;
            display: none;
        }
        &&:nth-of-type(even) .MuiTimelineItem-root {
            flex-direction: row;
        }
        &&:nth-of-type(even) .MuiTimelineContent-root {
            transform: translateX(20px);
        }
        &&:nth-of-type(even):hover .MuiTimelineDot-root {
            transition: 0.5s;
            z-index: 2;
            transform: translate(10px, 0px) rotateZ(0deg);
        }
    }
`;
