import { createSlice } from "@reduxjs/toolkit";


// 320 480 600 768 900 1024 1200

export const ElementsSlice = createSlice({
    name: "elements",
    //initialState:  ["profileContainer", "ProjectsContainer", "SkillsContainer", "TestimonialContainer", "ContactsContainer"],
    initialState: [],
    reducers: {
        // setElements : (state, action)=> {
        //     state = action.payload
        //     return state
        // },
        addElement : (state, action)=> {
            let duplicates = state.filter(elm=> elm.index===action.payload.index).length
            if(duplicates===0)
            state = [...state, action.payload]
            // console.log(state)
            return state
        },
        updateElement : (state, action)=> {
            state = state.map((elm, i)=> action.payload.index===i? {...elm, animationComplete : action.payload.animationComplete}: elm )
            // console.log(state)
            return state
        }
    }
})

export const {updateElement, addElement} = ElementsSlice.actions;