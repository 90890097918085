import React from 'react'
import styled from 'styled-components'
import { Container, Grid } from '@mui/material'
import { motion } from 'framer-motion'

import { setFinishComponentLoad } from '@/context/redux.loading'
import { useDispatch } from 'react-redux'

import { RightSide } from './RightSide'
import { LeftSide } from './LeftSide'
import { AboutMe } from './AboutMe'



const profileVariants = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.5,
            when: "beforeChildren",
            delayChildren: 0.2,
            // staggerChildren: 1.5
        }
    },
    hover: {  }
}

export const Profile = (props) => {

    const dispatch = useDispatch()
    const handleAnimationComplete = (definition)=>{
        dispatch(setFinishComponentLoad('profile'))
    }

    return (
        <motion.div ref={props.scrollTo}
                    // animate={controls}
                    initial={"hidden"}
                    variants={profileVariants}
                    whileInView={"visible"}
                    viewport={{once:true, amount:0.2}}
                    onAnimationComplete={ definition => handleAnimationComplete(definition)}
        >
            <ProfileContainer container>

                <Container className='topContainer' >
                    <LeftSide order={window.innerWidth>=930? 1:2} />
                    <RightSide order={window.innerWidth<930? 1:2} />
                </Container>

                <Container className='bottomContainer' >
                    <AboutMe order={window.innerWidth>=930? 3:2} />
                </Container>

            </ProfileContainer>
        </motion.div>
    )
}



const ProfileContainer = styled(Grid)`
    && {
        background: linear-gradient(180deg, rgba(var(--rgb-primary-color), 0.01) 0%,
                                            rgba(var(--rgb-secondary-color), 0.1) 100%);
    }

    && .topContainer, && .bottomContainer{
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        background: transparent;
    }
    && .topContainer {
        margin-top: 60px;
        width: 100%;
    }
    && .bottomContainer {
        justify-content: center;
    }

    @media screen and (max-width : 930px){
        && .topContainer {
            flex-direction: column-reverse;
            flex-wrap: nowrap;
            margin-top: 60px;
        }
        && .bottomContainer {
            margin-top: 40px;
            width: 100%;
        }
    }

`
