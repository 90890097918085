import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setCurrentComponent } from '@/context/redux.loading'
import { useHandleScroll } from './useHandleScroll'

export const useHandleNavBar = () => {

    const dispatch = useDispatch()

    const [isScrolling, setIsScrolling] = useState(false)

    const { ref:scrollToProfile,        inView:profileInView,       entry:profileEntry      } = useHandleScroll({isScrolling, viewIndex: 0})
    const { ref:scrollToProjects,       inView:pojectsInView,       entry:pojectsEntry      } = useHandleScroll({isScrolling, viewIndex: 1})
    const { ref:scrollToSkills,         inView:skillsInView,        entry:skillsEntry       } = useHandleScroll({isScrolling, viewIndex: 2})
    const { ref:scrollToTestimonials,   inView:testimonialsInView,  entry:testimonialsEntry } = useHandleScroll({isScrolling, viewIndex: 3})
    const { ref:scrollToContacts,       inView:contactsInView,      entry:contactsEntry     } = useHandleScroll({isScrolling, viewIndex: 4})
    
    const refs      = [scrollToProfile, scrollToProjects, scrollToSkills, scrollToTestimonials, scrollToContacts]
    const inViews   = [profileInView, pojectsInView, skillsInView, testimonialsInView, contactsInView]
    const entries   = [profileEntry, pojectsEntry, skillsEntry, testimonialsEntry, contactsEntry]


    const [selectedItem, setSelectedItem] = useState(0)

    let scrollingTimer
    const handleNavBarChange = (event, newValue) => {
        let scrollTo = entries[newValue]
        clearTimeout(scrollingTimer)
        setIsScrolling(prevScrolling=> true)
        setSelectedItem(prevValue => newValue)
        dispatch(setCurrentComponent(newValue))
        scrollTo.target.scrollIntoView({ behavior: 'smooth'})
        scrollingTimer = setTimeout(() => {
            setIsScrolling(prevScrolling=> false)
            // setSelectedItem(prevValue => newValue)
        }, 850);
    };

    const { currentComponent } = useSelector(state=> state.loading)

    useEffect(()=>{
        setSelectedItem(currentComponent)
        // console.log(`currentComponent : ${currentComponent}`)
    }, [currentComponent])

    return { refs, inViews, entries, handleNavBarChange, isScrolling, setIsScrolling, selectedItem, setSelectedItem }

}
